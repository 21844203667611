import { handleQuickViewClickAnalytics, getProdvmFromDatabridge } from '@xp-utilities/web';

export const handleOnRecommendedProductClick = (event, product = {}) => {
  const {
    brand,
    collection,
    faceout,
    id,
    imageId,
    name,
  } = product;

  const eventDetails = {
    productId: id,
    collectionId: collection,
    imageId,
    productName: name,
    productBrand: brand,
    returnFocus: event.target,
    faceout,
  };

  const prodvm = getProdvmFromDatabridge();
  const quickviewEvent = new CustomEvent('quickview:openModal', { detail: { ...eventDetails, prodvm } });

  handleQuickViewClickAnalytics();
  window.dispatchEvent(quickviewEvent);
};
