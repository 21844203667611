import { useEffect } from 'react';

export const LEGAL_POLICY_MODAL_TRIGGER = 'js-legal-policy-modal';

/**
 * Custom hook to handle click events on elements with the LEGAL_POLICY_MODAL_TRIGGER class.
 * @param {object} containerRef - React ref object for the container element.
 * @param {function} callback - Function to call when a valid element is clicked.
 */

const useLegalLinksEvent = (containerRef, callback) => {
  useEffect(() => {
    const elements = containerRef.current;
    if (!elements) return undefined;

    const elementsArray = Array.isArray(elements) ? elements : [elements];

    const handleClick = (event) => {
      const target = event.target.closest(`.${LEGAL_POLICY_MODAL_TRIGGER}`);

      // Check if the target is inside any of the elements in elementsArray
      const isLegalContainer = elementsArray.some((el) => el.contains(target));

      if (target && isLegalContainer) {
        const key = target.getAttribute('data-textkey');
        if (key) {
          callback(key);
        }
      }
    };

    elementsArray.forEach((element) => element?.addEventListener?.('click', handleClick));
    return () => {
      elementsArray.forEach((element) => element?.removeEventListener?.('click', handleClick));
    };
  }, [containerRef, callback]);
};

export default useLegalLinksEvent;
