import React from 'react';
import hydrate from '../lib/hydrate';
import { AddressModalCRSClient } from '../lib/components/Address/AddressModal/AddressModalCRS';

function SavedAddressModalFrontend() {
  return <AddressModalCRSClient />;
}

hydrate({
  frontend: 'SavedAddressModalFrontend',
  component: SavedAddressModalFrontend,
});
