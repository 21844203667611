import PropTypes from 'prop-types';
import { AUTH_FORMS } from './constants';

export const AuthenticationFormContextProviderPropTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.oneOf(Object.values(AUTH_FORMS)),
  redirect: PropTypes.string,
};

export const AuthenticationFormContextProviderDefaultProps = {
  form: AUTH_FORMS.SIGN_IN,
  redirect: undefined,
};
