import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Button, Icon, Modal } from 'anf-core-react';
import { GIFTCARD_BALANCE_DISPLAY_QUERY } from './operations';
import Loading from '../Common/Loading/Loading';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { TmntText } from '../Common/Text';
import EncodedText from '../Common/Text/EncodedText';

const propTypes = {
  onCheckAnotherCardBalance: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  giftCardBalance: PropTypes.string,
  isOpen: PropTypes.bool,
};

const defaultProps = {
  giftCardBalance: '0',
  isOpen: false,
};

export default function GiftCardBalanceSuccess({
  giftCardBalance,
  isOpen,
  onCheckAnotherCardBalance,
  onClose,
}) {
  const { data, loading, error } = useQuery(GIFTCARD_BALANCE_DISPLAY_QUERY, {
    skip: !isOpen, // Skip query if modal is not open
  });

  if (error) return ERROR_MESSAGE;

  const textFor = data?.textFor || {};

  return (
    <Modal
      id="gc-balance-success-modal"
      className="gc-balance-success"
      isOpen={isOpen}
      onClose={onClose}
      variant="headerless"
    >
      <Loading isLoading={loading}>
        {!loading && (
          <div className="gc-balance-content" data-testid="gc-balance-content">
            <div className="gc-balance-success__content">
              <Icon icon="giftcard" size="l" />
              <div className="h2 gc-balance-success__title" role="status">
                <TmntText tmnt={textFor?.checkYourBalanceHeadingText} />
              </div>
              <p className="gc-balance-success__desc">
                <TmntText tmnt={textFor?.cardBalance} />
                {/* Parse any currency symbols */}
                <EncodedText value={giftCardBalance} />
              </p>
            </div>
            <div className="gc-balance-success__actions">
              <Button
                variant="secondary"
                isFullWidth
                onClick={onCheckAnotherCardBalance}
              >
                <TmntText tmnt={textFor?.checkBalanceOnAnotherCard} />
              </Button>
            </div>
          </div>
        )}
      </Loading>
    </Modal>
  );
}

GiftCardBalanceSuccess.propTypes = propTypes;
GiftCardBalanceSuccess.defaultProps = defaultProps;
