import React from 'react';
import hydrate from '../lib/hydrate';
import { AuthenticationModalClient } from '../lib/components';

function AuthenticationModalFrontend() {
  return <AuthenticationModalClient />;
}

hydrate({
  frontend: 'AuthenticationModalFrontend',
  component: AuthenticationModalFrontend,
  persist: true,
});
