import React from 'react';
import SelfServiceOrderHelpPages from '../lib/components/CustomerSelfService/SelfServiceOrderHelpPages';
import AuthenticationModal from '../lib/components/SignIn/AuthenticationModal';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';
import {
  AuthenticationModalContext,
  ModalContextProvider,
} from '../lib/context/ModalContext';
import hydrate from '../lib/hydrate';

const frontend = 'SelfServiceOrderHelpPagesFrontend';

function SelfServiceOrderHelpFrontend() {
  return (
    <ModalContextProvider context={AuthenticationModalContext}>
      <AuthenticationFormContextProvider>
        <AuthenticationModal />
        <SelfServiceOrderHelpPages />
      </AuthenticationFormContextProvider>
    </ModalContextProvider>
  );
}

hydrate({
  frontend,
  component: SelfServiceOrderHelpFrontend,
});
