import React, { useEffect, useState } from 'react';
import LegalModal from './LegalModal';
import { ModalContextProvider } from '../../../context/ModalContext';

export default function LegalModalEventWrapper() {
  const [legalTmntKey, setLegalTmntKey] = useState(null);

  const handleEventTrigger = (event) => {
    setLegalTmntKey(event?.detail?.tmntKey);
  };

  useEffect(() => {
    document.addEventListener('legal-modal:open', handleEventTrigger);
    return () => {
      document.removeEventListener('legal-modal:open', handleEventTrigger);
    };
  });

  return (
    legalTmntKey
    && (
    <ModalContextProvider>
      <LegalModal
        legalText={legalTmntKey}
        onClose={() => setLegalTmntKey(null)}
      />
    </ModalContextProvider>
    )
  );
}
