import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { LegalText } from 'anf-core-react';
import { TmntHtml } from '../Text';
import { textProp } from '../../../tools/customProps';

const propTypes = { text: textProp.isRequired, tag: PropTypes.oneOf(['p', 'span']) };
const defaultProps = { tag: 'span' };

const LegalButton = forwardRef(
  ({ tag, text }, ref) => (
    <span ref={ref} data-testid="legal-modal-button">
      <LegalText>
        <TmntHtml tmnt={text} tag={tag} />
      </LegalText>
    </span>
  ),
);

export default LegalButton;

LegalButton.propTypes = propTypes;
LegalButton.defaultProps = defaultProps;
