import { gql } from '@apollo/client';
import { textPairFragmentStr } from '../../../query/fragmentStrings';

export const LEGAL_MODAL_CONTENT_QUERY = gql`
  query LEGAL_MODAL_CONTENT_QUERY($key: String!, $bundle: String) {
    textFor {
      legalContent: legalContent(contentKey: $key, bundle: $bundle) {
        ...textPair
      }
    }
  }

  ${textPairFragmentStr}
`;
