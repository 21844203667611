export const NAMES = {
  firstName: 'firstName',
  lastName: 'lastName',
  gender: 'gender',
  birthMonth: 'birth-month',
  birthDay: 'birth-day',
  birthYear: 'birth-year',
  email: 'email',
  password: 'current-password',
  phoneNumber: 'phoneNumber',
};

// needed in order to translate values from shopping preferences options
export const GENDER_OPTION_MAP = {
  male: 'M',
  female: 'F',
  na: 'N',
};
