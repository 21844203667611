import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import DiscountCardContainer from '../Common/DiscountCard/DiscountCardContainer';
import { FormCell } from '../FormStructure';
import { REWARDS_TEXT_QUERY } from './operations';

const propTypes = { isMobileView: PropTypes.bool };
const defaultProps = { isMobileView: false };

export default function RewardsLink({ isMobileView }) {
  const { loading, error, data } = useQuery(REWARDS_TEXT_QUERY, {
    ssr: false,
    context: { batch: true },
    refetchPolicy: 'network-only',
  });

  if (loading || error) return null;

  const { textFor = {}, coupons = [] } = data;
  const { redeemInBagText, exclusionsApplyLabelText } = textFor;

  const renderDiscountDetails = (text, href) => (
    <a
      target="_self"
      rel="noreferrer"
      href={href}
      style={{ display: 'block' }}
      className="open-modal-link"
    >
      {text}
    </a>
  );

  return (
    <FormCell>
      <div className="rewards-coupons">
        {coupons.slice(0, 5).map(({
          title, description, badge, expiration, exclusionLink, id,
        }) => (
          <DiscountCardContainer
            key={`discountcard_${id}`}
            discountTitle={title.value}
            discountSecondaryTitle={description?.key ? description?.value : ''}
            graphicBadge={badge}
            discountDescription={expiration.value}
            id={id}
            discountMessage={redeemInBagText.value}
            discountDetails={renderDiscountDetails(exclusionsApplyLabelText?.value, exclusionLink)}
            orientation={isMobileView ? 'stacked' : null}
          />
        ))}
      </div>
    </FormCell>
  );
}

RewardsLink.propTypes = propTypes;
RewardsLink.defaultProps = defaultProps;
