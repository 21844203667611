import React, { useState, useEffect } from 'react';
import {
  Button,
  FieldGroup,
  HorizontalSeparator,
  Icon,
} from 'anf-core-react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Form } from '../Common/Form';
import { TmntText } from '../Common/Text';
import * as FormStructure from '../FormStructure';
import { SIGN_IN_FORM_QUERY } from './operations';
import { ERROR_MESSAGE } from '../Messages/Messages';
import KeepMeSignedIn from '../Common/KeepMeSignedIn/KeepMeSignedIn';
import { KEEP_ME_SIGN_IN_INPUT_NAME } from '../Common/KeepMeSignedIn/KeepMeSignedInCheckbox';
import { LOYALTY_CONVERSION_INPUT_NAME } from './LoyaltyConversion';
import CreateAPasswordModal from '../CreateAPasswordFlow/CreateAPasswordModal';
import ChangePasswordModalContainer from '../ChangePasswordBlock/ChangePasswordModalContainer';
import StepOne from './StepOne';
import useButtonState from '../Common/ButtonState/useButtonState';
import ESpot from '../ESpot/ESpot';
import ModalContextProvider from '../../context/ModalContext/ModalContextProvider';
import { useSignInFormLogin } from '../../hooks';
import { FormErrorCell, LoyaltyConversionToggle } from './SignInFormHelpers';
import { SignInFormPropTypes, SignInFormDefaultProps } from './props';
import { useCustomTealiumTrigger } from '../../hooks/useTealiumTrigger';

let textCache = null;

export default function SignInForm({
  email,
  keepMeSignedIn,
  onFailedSubmission,
  onJoinClick,
  onSuccessfulSubmission,
  password,
}) {
  const [submitCall, setSubmitCall] = useState();
  const [renderButton, registerPromise] = useButtonState();

  const defaultValues = {
    email,
    password,
    [KEEP_ME_SIGN_IN_INPUT_NAME]: keepMeSignedIn,
  };

  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    setError,
  } = useForm({ defaultValues });

  const [
    runLogin,
    isLoyaltyConversionShown,
    isChangePasswordModalOpen,
  ] = useSignInFormLogin({
    getPrimaryFormError: () => textCache?.errorSomethingWentWrong,
    getValues,
    onFailedSubmission,
    onSuccessfulSubmission,
    setError,
  });

  const { data = {}, loading, error } = useQuery(SIGN_IN_FORM_QUERY);

  useEffect(() => {
    if (isChangePasswordModalOpen) {
      document.dispatchEvent(new CustomEvent('change-password-modal:open'));
    }
  }, [isChangePasswordModalOpen]);

  /* Analytics setup */
  const { userCountry, signifydId } = data;

  const analyticsData = {
    eventName: 'analytics.login_success',
    eventData: {
      event_type: 'login_success',
      site_country: userCountry || '',
      user_device_id: signifydId || '',
      user_wcs_id: '',
      user_state: 'authenticated',
    },
  };

  const trackSuccessfulSignIn = useCustomTealiumTrigger(analyticsData);

  /* Handlers */
  const buildSubmissionData = (formData) => ({
    email: formData.email,
    password: formData.password,
    keepMeSignedIn: formData[KEEP_ME_SIGN_IN_INPUT_NAME],
    isLoyaltyConversionTermsAccepted: formData[LOYALTY_CONVERSION_INPUT_NAME],
  });

  const handleOnInvalidForm = () => setError('form', { message: textCache?.signInForm?.error });

  const handleSignIn = async (formData) => {
    const variables = buildSubmissionData(formData);

    try {
      const call = new Promise((resolve, reject) => {
        runLogin({ variables })
          .then(({ data: responseData }) => {
            const { login } = responseData;
            const { success, userId } = login;
            if (!success) reject();
            else {
              analyticsData.eventData.user_wcs_id = userId;
              trackSuccessfulSignIn();
              resolve();
            }
          })
          .catch(() => reject());
      });

      setSubmitCall(call);
      registerPromise(call);
    } catch { /* do nothing */ }
  };

  const handleOnValidForm = async (formData = {}) => handleSignIn(formData);

  const handleOnSubmit = (event) => {
    clearErrors();
    return handleSubmit(handleOnValidForm, handleOnInvalidForm)(event);
  };

  if (error) return ERROR_MESSAGE;

  const { textFor = {} } = data;
  const { config = {} } = data;
  const { signInForm = {} } = textFor;

  textCache = textFor;

  /* Render Methods */
  const renderFormLabel = (label, isLoading) => (
    <FormStructure.FormGroup>
      <FormStructure.FormCell isLoading={isLoading} isHeader>
        <h1 className="h1">
          <TmntText tmnt={label} />
        </h1>
      </FormStructure.FormCell>
    </FormStructure.FormGroup>
  );

  const renderPrimaryGroup = (groups = []) => {
    if (groups?.length < 1) return null;

    /* Only one primary group - email/password */
    const group = groups[0];
    const { label, fieldList } = group;

    return (
      <FieldGroup legend={label?.value}>
        <StepOne
          fields={fieldList}
          control={control}
          showLabel={textCache?.showPasswordText?.value}
          hideLabel={textCache?.hidePasswordText?.value}
        />
      </FieldGroup>
    );
  };

  const renderEspot = (espotId, isLoyaltyEnabled, isLoading) => {
    if (!isLoyaltyEnabled) return null;

    return (
      <FormStructure.FormCell isLoading={isLoading}>
        <ESpot espotId={espotId} />
      </FormStructure.FormCell>
    );
  };

  const {
    id = 'sign-in-form',
    label,
    formGroupList,
  } = signInForm;

  return (
    <Form
      id={id}
      label={renderFormLabel(label, loading)}
      isValidated={false}
      onSubmit={handleOnSubmit}
    >
      <FormStructure.FormGroup>
        { renderEspot('customer-signin-header', config?.isLoyaltyEnabled, loading) }
        <FormStructure.FormCell isLoading={loading}>
          { renderPrimaryGroup(formGroupList) }
        </FormStructure.FormCell>
        <FormStructure.FormCell isLoading={loading}>
          <KeepMeSignedIn id="sign-in-form-keep-me-signed-in" control={control} />
        </FormStructure.FormCell>
        <LoyaltyConversionToggle
          control={control}
          isLoading={loading}
          isLoyaltyConversionShown={isLoyaltyConversionShown}
          submitCall={submitCall}
        >
          <FormErrorCell formState={formState} />
          <FormStructure.FormCell isLoading={loading}>
            {
              renderButton({
                initial: {
                  children: (<TmntText tmnt={textFor?.signIn} />),
                  isFullWidth: true,
                  type: 'submit',
                  variant: 'secondary',
                },
                processing: {
                  children: (<TmntText tmnt={textFor?.processing} />),
                  isFullWidth: true,
                  variant: 'secondary',
                },
                error: {
                  children: (<TmntText tmnt={textFor?.pleaseTryAgain} />),
                  isFullWidth: true,
                  variant: 'secondary',
                },
                success: {
                  children: (
                    <>
                      <Icon icon="check" />
                      <TmntText tmnt={textFor?.success} />
                    </>
                  ),
                  isFullWidth: true,
                  variant: 'secondary',
                },
              })
            }
          </FormStructure.FormCell>
          <FormStructure.FormGroup>
            <FormStructure.FormCell isLoading={loading}>
              <Button
                variant="tertiary-dark"
                onClick={onJoinClick}
                type="button"
                isFullWidth
              >
                <TmntText tmnt={textFor?.createAccount} />
              </Button>
            </FormStructure.FormCell>
          </FormStructure.FormGroup>
          <FormStructure.FormCell isLoading={loading} align="center">
            <ModalContextProvider>
              <CreateAPasswordModal id="forgot-password" />
            </ModalContextProvider>
          </FormStructure.FormCell>
        </LoyaltyConversionToggle>
      </FormStructure.FormGroup>
      <FormStructure.FormGroup>
        <FormStructure.FormCell isLoading={loading}>
          <HorizontalSeparator>
            <TmntText tmnt={textFor?.or} />
          </HorizontalSeparator>
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
      {/* Sign Up In Store */}
      <FormStructure.FormGroup>
        <FormStructure.FormCell isLoading={loading}>
          <ModalContextProvider>
            <CreateAPasswordModal id="create-new-password" />
          </ModalContextProvider>
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
      { isChangePasswordModalOpen && (<ChangePasswordModalContainer />) }
    </Form>
  );
}

SignInForm.propTypes = SignInFormPropTypes;
SignInForm.defaultProps = SignInFormDefaultProps;
