import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  Icon, IconBlock, Button, Link as DSLink,
} from 'anf-core-react';
import { AuthenticationModalContext } from '../../../context/ModalContext';
import { LOADING_MESSAGE, ERROR_MESSAGE } from '../../Messages/Messages';
import { TmntText } from '../Text';
import { LOGGED_OUT_LEFT_NAV_QUERY } from './operations';

export default function LoggedOutLeftNav() {
  const { openModal } = useContext(AuthenticationModalContext);
  const { data, loading, error } = useQuery(LOGGED_OUT_LEFT_NAV_QUERY);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { textFor, config } = data;

  const handleSignIn = () => {
    const detail = { isSignIn: true, isHBNav: true };
    if (!config?.enableMFEAuthenticationModal) {
      document.dispatchEvent(new CustomEvent('authentication-modal:open', { detail }));
    } else {
      openModal();
    }
  };

  return (
    <div className="acct-left-nav account-left-nav--guest" data-testid="guest-nav">
      <div className="acct-left-nav__content">
        <DSLink classList="acct-left-nav__title" href={config?.helpPageUrl}>
          <IconBlock
            icon={<Icon icon="question-outline-large" size="l" />}
            orientation="vertical"
          >
            <h1 className="h1">
              <TmntText tmnt={textFor?.helpText} />
            </h1>
          </IconBlock>
        </DSLink>
      </div>
      <div className="acct-left-nav__content">
        <hr className="acct-left-nav__separator" />
      </div>
      <div className="acct-left-nav__content">
        <p>
          <TmntText tmnt={textFor?.guestNavText} />
        </p>
        <Button
          classList="acct-left-nav__button"
          variant="tertiary-light"
          onClick={handleSignIn}
          isFullWidth
        >
          <TmntText tmnt={textFor?.signInText} />
        </Button>
      </div>
    </div>
  );
}
