import { useState } from 'react';

/**
 * @function useBirthdayValidation
 * @description Returns a function to validate a user's birthday.
 *
 * @param {String} month - The birth month (1 indexed, January is 1).
 * @param {String} day - The birth day.
 * @param {String} year - The year of birth.
 *
 * @returns {Object} - An object containing the following properties and methods:
 *   - {Boolean} isMonthInvalid - Indicates if the provided birth month is invalid.
 *   - {Boolean} isDayInvalid - Indicates if the provided birth day is invalid.
 *   - {Boolean} isYearInvalid - Indicates if the provided birth year is invalid.
 *   - {Function} validateBirthDay - A function to validate a user's birthday.
 *
 */
export default function useBirthdayValidation(
  month,
  day,
  year,
) {
  const [isMonthInvalid, setIsMonthInvalid] = useState(false);
  const [isDayInvalid, setIsDayInvalid] = useState(false);
  const [isYearInvalid, setIsYearInvalid] = useState(false);

  const validateBirthDay = (legalSubscribeAge) => {
    let isValid = true;
    let showFormAgeError = false;

    if (!month && !day && !year) return { isValid, showFormAgeError };

    setIsMonthInvalid(false);
    setIsDayInvalid(false);
    setIsYearInvalid(false);

    if (!month) {
      setIsMonthInvalid(true);
      isValid = false;
    }

    if (!day) {
      setIsDayInvalid(true);
      isValid = false;
    }

    if (!year || parseInt(year, 10) < 1900) {
      setIsYearInvalid(true);
      isValid = false;
    }

    // short circuit if month, day, or year are invalid
    if (!isValid) return { isValid, showFormAgeError };

    // Rollover date will roll to a valid date in the following month
    // ex: April has 30 days. Entering April 31 on the form will return May 1
    // Subtract 1 because JavaScript's Date object expects months to be 0-indexed
    const rolloverDate = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
    ).getDate();

    const dayOfMonthEntered = parseInt(day, 10);

    // Checking if date entered is valid. If there is a mismatch, set day as invalid
    if (rolloverDate !== dayOfMonthEntered) {
      setIsDayInvalid(true);
      isValid = false;
    }

    const todaysDate = new Date();

    // formDatePlusLegalAge will be the date entered in the form plus the legalAge years
    const formDatePlusLegalAge = new Date(
      parseInt(year, 10) + legalSubscribeAge,
      parseInt(month, 10) - 1,
      parseInt(day, 10),
    );

    // If today's date is less than formDatePlusLegalAge, set year as invalid
    if (todaysDate < formDatePlusLegalAge) {
      setIsYearInvalid(true);
      showFormAgeError = true;
      isValid = false;
    }

    return { isValid, showFormAgeError };
  };

  return {
    isMonthInvalid,
    isDayInvalid,
    isYearInvalid,
    validateBirthDay,
  };
}
