import React from 'react';
import { gql, useQuery } from '@apollo/client';
import FooterEmailSubscribeForm from './FooterEmailSubscribeForm';
import FooterEmailSubscribeButton from './FooterEmailSubscribeButton';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';

const FOOTER_SUBSCRIBE_QUERY = gql`
  query FOOTER_SUBSCRIBE_QUERY {
    config {
      hasFooterSubscribeForm: bool(name: "hasFooterSubscribeForm")
    }
  }
`;

export default function FooterEmailSubscribeBlock() {
  const { loading, error: queryError, data: queryData } = useQuery(FOOTER_SUBSCRIBE_QUERY);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { config } = queryData;
  const { hasFooterSubscribeForm } = config;

  return hasFooterSubscribeForm
    ? <FooterEmailSubscribeForm />
    : <FooterEmailSubscribeButton />;
}
