import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useQuery } from '@apollo/client';
import { RECAPTCHA_KEY_CONFIG } from './operations';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../../Messages/Messages';
import { reCaptchaPropTypes } from './props';

const ReCaptcha = forwardRef(({ onChange }, ref) => {
  const { loading, error: queryError, data: queryData } = useQuery(RECAPTCHA_KEY_CONFIG);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { reCaptchaSiteKey } = queryData.config;

  return (
    <ReCAPTCHA
      data-testid="recaptcha"
      onExpired={() => onChange('')}
      onChange={onChange}
      ref={ref}
      sitekey={reCaptchaSiteKey}
    />
  );
});

ReCaptcha.propTypes = reCaptchaPropTypes;

export default ReCaptcha;
