import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Modal } from 'anf-core-react';
import AuthenticationForm from './AuthenticationForm';
import { AuthenticationModalContext, ModalContextProvider } from '../../context/ModalContext';
import { useEventListener, useInstance } from '../../hooks';
import { ERROR_MESSAGE } from '../Messages/Messages';
import SuccessModal from '../SuccessModal/SuccessModal';
import {
  AuthenticationFormContextProvider,
  useAuthenticationForm,
  useAuthenticationFormDispatch,
} from '../../context/AuthenticationFormContext/AuthenticationFormContext';
import { AUTH_FORMS } from '../../context/AuthenticationFormContext/constants';

const SUCCESS_MODAL_FLAG_QUERY = gql`
  query SuccessModalFlag {
    isSuccessModalEnabled: featureFlag(
      key: "has-mfe-success-modal-enabled",
      defaultValue: false
    )
    config {
      isLoyaltyEnabled: bool(name: "isLoyaltyEnabled")
    }
  }
`;

export const AUTH_MODAL_ID = 'authentication-modal';

export default function AuthenticationModal() {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const authModalContext = useContext(AuthenticationModalContext);
  const { isModalOpen, closeModal } = authModalContext;
  const dispatch = useAuthenticationFormDispatch();
  const { form } = useAuthenticationForm();
  /*
    Note: Adding an instance to ensure that only a single instance of the auth modal is
    opened at a time. Issue arose when soft auth is activated. The `event.target`
    initializes the instance value and then blocks subsequent open calls.
  */
  const [getInstance, setInstance] = useInstance(AUTH_MODAL_ID);

  const handleOnOpenModal = () => {
    const { isModalOpen: isOpen, openModal: openModalInstance } = getInstance();
    if (!isOpen) openModalInstance();
  };

  const handleOnOpenEvent = (event) => {
    const { isSignIn = true } = event.detail;

    if (!getInstance()) setInstance(authModalContext);
    handleOnOpenModal();

    if (!isSignIn) dispatch({ type: 'showForm', form: AUTH_FORMS.JOIN });
    else dispatch({ type: 'showForm', form: AUTH_FORMS.SIGN_IN });
  };

  const handleOnClose = () => {
    closeModal();
    setInstance(null);
  };

  const { data, loading, error } = useQuery(SUCCESS_MODAL_FLAG_QUERY, {
    ssr: false,
  });

  useEventListener(`${AUTH_MODAL_ID}:open`, handleOnOpenEvent);

  // Ensure the auth modal instance is always cleaned up
  useEffect(
    () => {
      if (!isModalOpen) setInstance(null);
      else setInstance(authModalContext);
    },
    [authModalContext, isModalOpen, setInstance],
  );

  if (loading) return null;
  if (error) return ERROR_MESSAGE;

  const { isSuccessModalEnabled } = data;
  const { isLoyaltyEnabled } = data.config;

  const openSuccessModal = () => (
    isSuccessModalEnabled
      ? setSuccessModalOpen(true)
      : document.dispatchEvent(new CustomEvent(`${AUTH_MODAL_ID}:success`))
  );

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    window.location.reload();
  };

  const handleOnSuccessfulSubmission = () => {
    handleOnClose();
    // open success modal if in loyalty enabled regions
    if (form === AUTH_FORMS.JOIN && isLoyaltyEnabled) openSuccessModal();
    else if (form === AUTH_FORMS.JOIN) setTimeout(() => window.location.reload(), 300);
  };

  return (
    <>
      <Modal
        id={AUTH_MODAL_ID}
        isOpen={isModalOpen}
        onClose={handleOnClose}
        variant="headerless"
      >
        <div className="scope-1892">
          <AuthenticationForm onSuccessfulSubmission={handleOnSuccessfulSubmission} />
        </div>
      </Modal>
      {isSuccessModalEnabled && (
        <SuccessModal isOpen={successModalOpen} onClose={handleCloseSuccessModal} />
      )}
    </>
  );
}

export function AuthenticationModalClient() {
  return (
    <AuthenticationFormContextProvider>
      <ModalContextProvider context={AuthenticationModalContext}>
        <AuthenticationModal />
      </ModalContextProvider>
    </AuthenticationFormContextProvider>
  );
}
