import React from 'react';
import hydrate from '../lib/hydrate';
import MobileSignIn from '../lib/components/SignIn/MobileSignIn';
import {
  AuthenticationModalContext,
  ModalContextProvider,
  SignInToasterContext,
} from '../lib/context/ModalContext';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';

function MobileSignInFrontend() {
  return (
    <ModalContextProvider context={AuthenticationModalContext}>
      <ModalContextProvider context={SignInToasterContext}>
        <AuthenticationFormContextProvider>
          <MobileSignIn />
        </AuthenticationFormContextProvider>
      </ModalContextProvider>
    </ModalContextProvider>
  );
}

hydrate({
  frontend: 'MobileSignInFrontend',
  component: MobileSignInFrontend,
  persist: true,
});
