import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowWidth } from '@react-hook/window-size';
import { useQuery } from '@apollo/client';
import { Button, Icon } from 'anf-core-react';
import { SUCCESS_MODAL_QUERY } from './operations';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import * as FormStructure from '../FormStructure';
import DiscountCardContainer from '../Common/DiscountCard/DiscountCardContainer';
import { TmntText } from '../Common/Text';

const propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default function SuccessContainer({ onClose }) {
  const [isMobile, setIsMobile] = useState(false);
  const { loading, error, data } = useQuery(SUCCESS_MODAL_QUERY);

  const windowWidth = useWindowWidth();

  // if window width changes to be below 1025px, update isMobile state
  useEffect(() => {
    const newIsMobile = windowWidth < 1025;
    if (newIsMobile !== isMobile) setIsMobile(newIsMobile);
  }, [windowWidth, isMobile]);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { textFor, config, coupons } = data;
  const successCoupon = coupons[0];

  const renderSuccessCoupon = () => {
    const {
      title,
      description,
      badge,
      expiration,
      id,
      exclusionLink,
    } = successCoupon;

    return (
      <DiscountCardContainer
        id={id}
        orientation={isMobile ? 'stacked' : null}
        discountTitle={title?.value}
        discountSecondaryTitle={description?.value}
        graphicBadge={badge}
        discountDescription={expiration?.value}
        discountMessage={textFor?.redeemInBagText.value}
        discountDetails={(
          <a
            target="_self"
            rel="noreferrer"
            href={exclusionLink}
            style={{ display: 'block' }}
            className="open-modal-link"
          >
            <TmntText tmnt={textFor?.redeemInBagText} />
          </a>
        )}
      />
    );
  };

  return (
    <FormStructure.FormWrapper>
      <FormStructure.FormGroup>
        <FormStructure.FormCell align="center">
          <div className="success-modal__icon">
            <Icon
              icon={config?.loyaltyWelcomeMessageImageUrl}
              labelText={textFor?.loyaltyTierImageAltText.value}
            />
          </div>
        </FormStructure.FormCell>
        <FormStructure.FormCell align="center">
          <div className="success-modal__title">
            <div className="h1">
              <TmntText tmnt={textFor.successModalHeader} />
            </div>
          </div>
          <div className="success-modal__desc">
            <TmntText tmnt={textFor.successModalBody} />
          </div>
        </FormStructure.FormCell>
        {successCoupon && (
          <FormStructure.FormCell align="center">
            <div className="success-modal__reward">
              {renderSuccessCoupon()}
            </div>
          </FormStructure.FormCell>
        )}
        <FormStructure.FormCell align="center">
          <div className="success-modal__cta">
            <Button
              variant="secondary"
              onClick={onClose}
              type="button"
              isFullWidth
            >
              <TmntText tmnt={textFor.successModalButtonText} />
            </Button>
          </div>
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
    </FormStructure.FormWrapper>
  );
}

SuccessContainer.propTypes = propTypes;
