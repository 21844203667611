import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';

const propTypes = {
  createdAt: PropTypes.string.isRequired,
  details: PropTypes.shape({
    description: textProp.isRequired,
    notes: textProp,
    transactionId: textProp,
  }).isRequired,
  pointBalance: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  isGiftTier: PropTypes.bool.isRequired,
};

const formatDate = (date) => {
  let newDate = new Date(date);
  const offset = newDate.getTimezoneOffset();
  newDate = new Date(newDate.getTime() - (offset * 60 * 1000)); // adjust for timezone
  return newDate.toISOString().split('T')[0];
};

const getClassModifiers = (pointBalance, status, isGiftTier) => {
  if (isGiftTier) return 'points-record--gift-tier';
  if (status === 'pending' || pointBalance === 0) return 'points-record--pending';
  return pointBalance < 0 ? 'points-record--points-lost' : 'points-record--points-gained';
};

const getBalanceDescriptor = (pointBalance) => (
  pointBalance < 0 ? 'minus' : 'plus'
);

export default function PointsRecord({
  createdAt,
  details,
  pointBalance,
  status,
  isGiftTier,
}) {
  const classModifiers = useMemo(() => (
    getClassModifiers(pointBalance, status, isGiftTier)
  ), [pointBalance, status, isGiftTier]);
  const balanceDescriptor = useMemo(() => getBalanceDescriptor(pointBalance), [pointBalance]);

  const formattedPointBalance = `${pointBalance < 0 ? '-' : '+'} ${Math.abs(pointBalance)}`;
  const formattedCreatedAt = formatDate(createdAt);

  return (
    <li className={`points-record ${classModifiers}`} data-testid="points-record">
      {pointBalance !== 0 && (
        <>
          <div className="points-record__balance">
            <span>{formattedPointBalance}</span>
            <span className="screen-reader-text">{balanceDescriptor}</span>
          </div>
          <div className="points-record__details">
            <p className="points-record__date">{formattedCreatedAt}</p>
            <div className="points-record__description">
              {(status === 'pending') && <span className="points-record__status">Pending: </span>}
              <TmntText classNames="points-record__message" tmnt={details.description} />
              {details.notes && <TmntText classNames="points-record__notes" tmnt={details.notes} />}
              {details.transactionId && <TmntText classNames="points-record__transactionId" tmnt={details.transactionId} />}
            </div>
          </div>
        </>
      )}
    </li>
  );
}

PointsRecord.propTypes = propTypes;
