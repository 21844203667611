export const USER_EVENT = Object.freeze({
  EMAIL_SUBSCRIBE: 'email subscribe',
  SIGN_IN: 'sign in or join',
  CREATE_PASSWORD: 'create a password',
  UPPER_FUNNEL_SIGN_IN: 'sign in or join - prompt',
  SIGN_IN_FAIL: 'invalid email or password',
  INVALID_EMAIL: 'invalid email',
  INVALID_PASSWORD: 'password does not meet requirements',
  INVALID_PHONE_NUMBER: 'invalid phone number',
  INVALID_CREATE_ACCOUNT: 'invalid account create information',
  CHAT_WITH_US: 'Chat With Us',
  EMAIL_US: 'Email Us',
  VIEW_PHONE_NUMBERS: 'View Phone Numbers',
  WHATSAPP: 'WhatsApp Phone Number',
  SWITCH_MY_ACCOUNT: 'I Agree Switch My Account',
});

export const USER_ACTION = Object.freeze({
  MARKETING: 'marketing',
  CLICK: 'click',
  OPEN: 'open',
  INVALID_SUBMIT: 'invalid submit',
  CLOSE: 'close',
});

export const getEventConfig = (featureType) => Object.freeze({
  EMAIL_SUBSCRIBE: {
    eventName: `${featureType}_email_subscribe_click`,
    eventData: {
      tealium_event: USER_ACTION.CLICK,
      data_text: USER_EVENT.EMAIL_SUBSCRIBE,
      data_action: USER_ACTION.MARKETING,
    },
  },
  SIGN_IN: {
    eventName: `${featureType}_sign_in_or_join_click`,
    eventData: {
      tealium_event: USER_ACTION.CLICK,
      data_text: USER_EVENT.SIGN_IN,
      data_action: USER_ACTION.OPEN,
    },
  },
  CREATE_PASSWORD: {
    eventName: `${featureType}_click`,
    eventData: {
      event_type: USER_ACTION.CLICK,
      data_text: USER_EVENT.CREATE_PASSWORD,
      data_action: USER_ACTION.OPEN,
    },
  },
  UPPER_FUNNEL_SIGN_IN: {
    eventName: `${featureType}_click`,
    eventData: {
      event_name: USER_ACTION.CLICK,
      data_text: USER_EVENT.UPPER_FUNNEL_SIGN_IN,
      data_action: USER_ACTION.OPEN,
    },
  },
  INVALID_EMAIL: {
    eventName: `${featureType}_click`,
    eventData: {
      event_type: USER_ACTION.CLICK,
      data_text: USER_EVENT.INVALID_EMAIL,
      data_action: USER_ACTION.INVALID_SUBMIT,
    },
  },
  SIGN_IN_FAIL: {
    eventName: `${featureType}_click`,
    eventData: {
      event_type: USER_ACTION.CLICK,
      data_text: USER_EVENT.SIGN_IN_FAIL,
      data_action: USER_ACTION.INVALID_SUBMIT,
    },
  },
  INVALID_PASSWORD: {
    eventName: `${featureType}_click`,
    eventData: {
      event_type: USER_ACTION.CLICK,
      data_text: USER_EVENT.INVALID_PASSWORD,
      data_action: USER_ACTION.INVALID_SUBMIT,
    },
  },
  INVALID_PHONE_NUMBER: {
    eventName: `${featureType}_click`,
    eventData: {
      event_type: USER_ACTION.CLICK,
      data_text: USER_EVENT.INVALID_PHONE_NUMBER,
      data_action: USER_ACTION.INVALID_SUBMIT,
    },
  },
  INVALID_CREATE_ACCOUNT: {
    eventName: `${featureType}_click`,
    eventData: {
      event_type: USER_ACTION.CLICK,
      data_text: USER_EVENT.INVALID_CREATE_ACCOUNT,
      data_action: USER_ACTION.INVALID_SUBMIT,
    },
  },
  CHAT_WITH_US: {
    eventName: `${featureType}_click`,
    eventData: {
      event_name: USER_ACTION.CLICK,
      data_text: USER_EVENT.CHAT_WITH_US,
      data_action: USER_ACTION.OPEN,
    },
  },
  EMAIL_US: {
    eventName: `${featureType}_click`,
    eventData: {
      event_name: USER_ACTION.CLICK,
      data_text: USER_EVENT.EMAIL_US,
      data_action: USER_ACTION.OPEN,
    },
  },
  VIEW_PHONE_NUMBERS: {
    eventName: `${featureType}_click`,
    eventData: {
      event_name: USER_ACTION.CLICK,
      data_text: USER_EVENT.VIEW_PHONE_NUMBERS,
      data_action: USER_ACTION.OPEN,
    },
  },
  WHATSAPP: {
    eventName: `${featureType}_click`,
    eventData: {
      event_name: USER_ACTION.CLICK,
      data_text: USER_EVENT.WHATSAPP,
      data_action: USER_ACTION.OPEN,
    },
  },
  SWITCH_MY_ACCOUNT: {
    eventName: `${featureType}_click`,
    eventData: {
      event_name: USER_ACTION.CLICK,
      data_text: USER_EVENT.SWITCH_MY_ACCOUNT,
      data_action: USER_ACTION.CLOSE,
    },
  },
});

export const collectDatabridgeDatapoints = (digitalData) => ({
  site_brand: digitalData.get('page.brand'),
  site_domain: digitalData.get('page.url.domain.top'),
  site_language: digitalData.get('page.language'),
  site_currency: digitalData.get('page.currency'),
  user_wcs_id: digitalData.get('user.id'),
  page_type: digitalData.get('page.pageKind'),
  location_web_store_id: digitalData.get('page.storeId'),
  page_url: `${digitalData.get('page.url.domain.top')}${digitalData.get('page.url.path')}`,
  site_platform: window?.navigator?.userAgent || '',
});
