import React from 'react';
import { useQuery } from '@apollo/client';
import { UPPER_FUNNEL_LOGIN_CLIENT_QUERY } from './operations';
import UpperFunnelLogin from './UpperFunnelLogin';
import AuthenticationModal from '../SignIn/AuthenticationModal';
import SignInRestructure from '../SignIn/SignInRestructure';

function UFLClient() {
  const { data, error, loading } = useQuery(
    UPPER_FUNNEL_LOGIN_CLIENT_QUERY,
    {
      context: { batch: true },
      fetchPolicy: 'cache-and-network',
      ssr: false,
    },
  );

  if (loading) return null;
  if (error) return null;

  const { userState } = data;
  const { isLoggedIn } = userState;

  if (isLoggedIn) return null;

  return (
    <>
      <AuthenticationModal />
      <UpperFunnelLogin />
    </>
  );
}

/*
  Note: temporary setup to encapsulate the sign in block around
  the sign in restructure flag
*/
export default function UpperFunnelLoginClient() {
  return (
    <SignInRestructure switchKey="upperFunnelLogin">
      <UFLClient />
    </SignInRestructure>
  );
}
