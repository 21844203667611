import React, { useEffect, useState } from 'react';
import AddressModal, { EVENTS as addressModalEvents } from './AddressModal';
import { AddressContextProvider } from '../AddressContextProvider';

export const EVENTS = { open: 'address-modal:open' };

export default function AddressModalCRS() {
  const [countryId, setCountryId] = useState(undefined);
  const [addressId, setAddressId] = useState(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const reloadPage = () => { window.location.reload(); };

  useEffect(() => {
    const openModal = ({ detail }) => {
      setIsOpen(true);

      if (!detail?.addressId) {
        setAddressId(undefined); // reset addressId
        setCountryId(detail?.countryId);
      } else {
        setCountryId(undefined);
        setAddressId(detail.addressId);
      }
    };

    document.addEventListener(EVENTS.open, openModal);

    return () => {
      document.removeEventListener(EVENTS.open, openModal);
    };
  }, []);

  useEffect(() => {
    const onSuccessfulUpdate = () => {
      setIsOpen(false);
      reloadPage();
    };

    document.addEventListener(addressModalEvents.updated, onSuccessfulUpdate);

    return () => { document.removeEventListener(addressModalEvents.updated, onSuccessfulUpdate); };
  }, []);

  const handleOnClose = () => setIsOpen(false);

  if (!countryId && !addressId) return <div data-testid="address-modal-crs" />;

  return (
    <div data-testid="address-modal-crs">
      <AddressModal
        addressId={addressId}
        countryId={countryId}
        isOpen={isOpen}
        onClose={handleOnClose}
      />
    </div>
  );
}

export function AddressModalCRSClient() {
  return (
    <AddressContextProvider>
      <AddressModalCRS />
    </AddressContextProvider>
  );
}
