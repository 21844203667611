import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useQuery } from '@apollo/client';
import SignInButton from './SignInButton';
import LoggedInButton from './LoggedInButton';
import SignInMenu from './SignInMenu';
import SignInToaster from './SignInToaster';
import { SIGN_IN_BLOCK_QUERY } from './operations';
import { SignInToasterContext } from '../../context/ModalContext';
import AuthenticationModal from './AuthenticationModal';
import UpperFunnelLogin from '../UpperFunnelLogin/UpperFunnelLogin';
import SignInRewardsCommunicationModal from './SignInRewardsCommunicationModal';
import { renderDesktopSignInLoading, renderDesktopSignInContainer } from './helpers';

export default function SignInBlock() {
  const [, setRenderCount] = useState(0);
  const { openModal: openToaster } = useContext(SignInToasterContext);

  const { data = {}, loading } = useQuery(
    SIGN_IN_BLOCK_QUERY,
    {
      ssr: false,
      context: { batch: true },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    const updatePortal = () => setRenderCount((prev) => prev + 1);
    document.addEventListener('render-signin-block', updatePortal);
    return () => { document.removeEventListener('render-signin-block', updatePortal); };
  }, []);

  if (loading) return renderDesktopSignInLoading();

  const {
    featureConfig,
    userState = {},
  } = data;

  const { isLoggedIn = false } = userState;

  // Sign In Restructure - Parse sign in restructure switches
  const { signInRestructureSwitch } = featureConfig;
  let restructureSwitches;

  try {
    restructureSwitches = JSON.parse(signInRestructureSwitch);
  } catch {
    restructureSwitches = { upperFunnelLogin: false };
  }

  // Pull out migrated pieces and systematically turn off pieces
  const { upperFunnelLogin: uflRestuctureSwitch } = restructureSwitches;

  const renderSignInMenuComponent = (location) => (
    <SignInMenu data-testId="sign-in-menu" isLoggedIn={isLoggedIn} location={location} />
  );

  return renderDesktopSignInContainer((
    <>
      {
        isLoggedIn
          ? <LoggedInButton onClick={openToaster} />
          : <SignInButton onClick={openToaster} />
      }
      <>
        {/* Auth Modal */}
        <AuthenticationModal />

        {/* Sign In Rewards Communication Modal */}
        {isLoggedIn && <SignInRewardsCommunicationModal /> }

        {/* Sign In Toaster */}
        <SignInToaster>
          { renderSignInMenuComponent() }
        </SignInToaster>

        {/* Mobile Sign In - */}

        {/* Upper Funnel */}
        {
          !uflRestuctureSwitch && !isLoggedIn
            ? createPortal(<UpperFunnelLogin />, document?.body)
            : null
        }
      </>
    </>
  ));
}
