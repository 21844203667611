import React from 'react';
import { Button } from 'anf-core-react';
import Icon from '../Common/Icon/Icon';
import { useCustomTealiumTrigger } from '../../hooks/useTealiumTrigger';
import { MobileSignInButtonPropTypes, MobileSignInButtonDefaultProps } from './props';

export default function MobileSignInButton({
  label,
  onClick,
}) {
  const analyticsData = {
    eventName: 'universal_click',
    eventData: {
      event_name: 'click',
      data_text: 'account toaster',
      data_action: 'open',
    },
  };

  const fireAnalyticsEvent = useCustomTealiumTrigger(analyticsData);

  const handleClick = () => {
    fireAnalyticsEvent();
    onClick();
  };

  return (
    <div
      className="mobile-sign-in-button-container"
      data-testid="mobile-sign-in-button-container"
    >
      <Button
        labelText={label?.value}
        variant="borderless"
        onClick={handleClick}
      >
        <Icon icon="account-circle" />
      </Button>
    </div>
  );
}

MobileSignInButton.propTypes = MobileSignInButtonPropTypes;
MobileSignInButton.defaultProps = MobileSignInButtonDefaultProps;
