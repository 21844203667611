import { useQuery } from '@apollo/client';
import { SIGN_IN_RESTRUCTURE_QUERY } from './operations';
import { SignInRestructurePropTypes, SignInRestructureDefaultProps } from './props';

/**
 * SignInRestructure Component
 *
 * A wrapper component that conditionally renders its children based on a feature flag
 * retrieved from launch darkly.
 *
 * NOTE: This is a temporary component and is planned to be cleaned up as well as the LD
 * switch: https://app.launchdarkly.com/projects/default/flags/customer-sign-in-restructure-switches/targeting?env=development&env=test&env=production&selected-env=development
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to render when the feature is enabled.
 * @param {React.ReactNode} [props.errorElement=null] - The element to display in case of an error.
 * @param {React.ReactNode} [props.hiddenElement=undefined] - The element to render when the feature
 * is disabled if defined.
 * @param {React.ReactNode} [props.loadingElement=null] - The element to render while the query
 * is loading.
 * @param {string} [props.switchKey=undefined] - The key used to index the feature flag object and
 * determines whether the feature is enabled.
 *
 * @returns {React.ReactNode} The appropriate element based on the feature flag state.
 */
export default function SignInRestructure({
  children,
  errorElement,
  hiddenElement,
  loadingElement,
  switchKey,
}) {
  const { loading, error, data = {} } = useQuery(SIGN_IN_RESTRUCTURE_QUERY, {
    context: { batch: true },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ssr: false,
  });

  if (loading) return loadingElement;
  if (error) return errorElement;

  const { featureConfig = { signInRestructureSwitch: '{}' } } = data;

  try {
    const isEnabled = JSON.parse(featureConfig.signInRestructureSwitch)[switchKey] ?? false;
    return !isEnabled ? hiddenElement ?? null : children;
  } catch {
    return errorElement;
  }
}

SignInRestructure.propTypes = SignInRestructurePropTypes;
SignInRestructure.defaultProps = SignInRestructureDefaultProps;
