import { gql } from '@apollo/client';
import { textPairFragment, apiResponseFragment } from '../../query/fragments';

export const enterEmailPaneQuery = gql`
query EnterEmailFormQuery {
  textFor {
    processing: pair(pairKey: "processing") { ...textPair }
    success: pair(pairKey: "success") { ...textPair }
    pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
    sendTempPasswordBtnText: pair(pairKey: "enterEmailPanesendTemporaryPassword") {...textPair}
    enterEmailPaneInstructions: pair(pairKey: "enterEmailPaneInstructions") {...textPair}
    invalidInputError: pair(pairKey: "invalidInputError") {...textPair}
    enterEmailPaneHeader: pair(pairKey: "enterEmailPaneHeader") {...textPair}
    email: pair(pairKey: "enterEmailPaneEmailInput") {...textPair}
    invalidEmailError: pair(pairKey: "invalidEmailError") {...textPair}
    enterEmailPaneInvalidEmail: pair(pairKey: "enterEmailPaneInvalidEmail") {...textPair}
    createAnAccount: pair(pairKey: "createAnAccount") {...textPair}
    legal: legal(fieldKey: "createAPasswordEmailPane") {
      text {...textPair}
    }
  }
}

${textPairFragment}
`;

export const enterEmailPaneRequest = gql`
  mutation SendTempPassword($email: String!) {
    sendTempPassword(email: $email) {
      ...response
    }
  }

  ${apiResponseFragment}
`;

export const temporaryPasswordPaneQuery = gql`
query TemporaryPasswordFormQuery {
  textFor {
    processing: pair(pairKey: "processing") { ...textPair }
    success: pair(pairKey: "success") { ...textPair }
    pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
    header: pair(pairKey: "temporaryPasswordPaneHeader") { ...textPair }
    temporaryPasswordPaneInstructions: pair(pairKey: "temporaryPasswordPaneInstructions") { ...textPair }
    temporaryPasswordLabel: pair(pairKey: "temporaryPasswordPanePasswordInput") { ...textPair }
    legal: pair(pairKey: "temporaryPasswordPaneLegalText") {...textPair}
    continueText: pair(pairKey: "continue") {...textPair}
    invalidPasswordError: pair(pairKey: "temporaryPasswordError") {...textPair}
    sendNewPassword: pair(pairKey: "temporaryPasswordPaneSendNewPassword") {...textPair}
    showPasswordText: pair(pairKey: "showPassword") {...textPair}
    hidePasswordText: pair(pairKey: "hidePassword") {...textPair}
  }
}

${textPairFragment}
`;

export const loginMutation = gql`
  mutation login(
    $email : String!,
    $password : String!,
    $keepMeSignedIn : Boolean
  ) {
    login (
      email : $email,
      password: $password,
      keepMeSignedIn: $keepMeSignedIn
    ) { success }
  }
`;

export const finishPaneQuery = gql`
  query FinishPaneQuery {
    textFor {
      finishPaneHeader: pair(pairKey: "finishPaneHeader") { ...textPair }
      finishPaneBodyText: pair(pairKey: "finishPaneBodyText") { ...textPair }
      finishPaneButton: pair(pairKey: "finishPaneButton") { ...textPair }
    }
  }

  ${textPairFragment}
`;

export const createPasswordPaneQuery = gql`
query CreatePasswordFormQuery {
  textFor {
    processing: pair(pairKey: "processing") { ...textPair }
    success: pair(pairKey: "success") { ...textPair }
    pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
    headerText: pair(pairKey: "createNewPassword") { ...textPair }
    headerInstructionsText: pair(pairKey: "createPasswordPaneInstructions") {...textPair}
    enterPasswordLabel: pair(pairKey: "createPasswordPaneEnterPassword") { ...textPair }
    confirmPasswordLabel: pair(pairKey: "confirmPassword") {...textPair}
    createPasswordRulesText: pair(pairKey: "passwordCharacterLength") {...textPair}
    createPasswordButtonText: pair(pairKey: "createPasswordPaneButtonCreateNewPassword") { ...textPair }
    showPasswordText: pair(pairKey: "showPassword") {...textPair}
    hidePasswordText: pair(pairKey: "hidePassword") {...textPair}
    invalidInputError: pair(pairKey: "invalidInputError") {...textPair}
    newPasswordMatchError: pair(pairKey: "newPasswordMatchError") {...textPair}
    createPasswordPanePasswordRequirements: pair(pairKey: "createPasswordPanePasswordRequirements") {...textPair}
    createPasswordPanePasswordMismatch: pair(pairKey: "createPasswordPanePasswordMismatch") {...textPair}
  }
  isPasswordStrengthEnabled: featureFlag(
    key: "is-password-strength-meter-enabled"
  ),
}

${textPairFragment}
`;

export const createPasswordRequest = gql`
  mutation CreatePassword($password: String!) {
    createPassword(password: $password) {
      ...response
    }
  }

  ${apiResponseFragment}
`;

export const createPasswordLinkQuery = gql`
  query CreatePasswordLinkQuery {
    textFor {
      createAPasswordLinkSubText: pair(pairKey: "createAPasswordLinkSubText") {...textPair}
      createAPassword: pair(pairKey: "createAPassword") {...textPair}
      forgotPassword: pair(pairKey: "forgotPassword") {...textPair}
    }
  }

  ${textPairFragment}
`;
