import React, { useContext, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Modal } from 'anf-core-react';
import PropTypes from 'prop-types';
import { TmntHtml } from '../Text';
import { ModalContext } from '../../../context/ModalContext/ModalContextProvider';
import { LEGAL_MODAL_CONTENT_QUERY } from './operations';
import { useInstance } from '../../../hooks';
import { LoadingButton } from '../../Messages/Messages';

const propTypes = {
  legalText: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

const defaultProps = {
  onClose: null,
};

export default function LegalModal({ legalText, onClose }) {
  const modalContext = useContext(ModalContext);

  const { isModalOpen, closeModal } = modalContext;
  const [getInstance, setInstance] = useInstance(legalText);

  useEffect(() => {
    if (!getInstance()) setInstance(modalContext);
  }, [getInstance, modalContext, setInstance]);

  const handleOnOpenModal = useCallback(() => {
    const { isModalOpen: isOpen, openModal: openModalInstance } = getInstance() || {};
    if (!isOpen && openModalInstance) openModalInstance();
  }, [getInstance]);

  const handleCloseModal = useCallback(() => {
    closeModal();
    if (onClose) onClose();
    setInstance(null);
  }, [closeModal, onClose, setInstance]);

  const { loading, error, data } = useQuery(LEGAL_MODAL_CONTENT_QUERY, {
    variables: { key: legalText },
    skip: !legalText, // Skip the query if legalText is not defined
    onCompleted: handleOnOpenModal,
  });

  if (error) return null;

  return (
    <div data-testid="legal-modal-wrapper">
      <Modal id={legalText} isOpen={isModalOpen} onClose={handleCloseModal} variant="headerless">
        {loading ? (
          <span data-testid="legal-modal-spinner" className="legal-modal-spinner">
            <LoadingButton showText={false} />
          </span>
        ) : (
          <TmntHtml tmnt={data?.textFor?.legalContent} />
        )}
      </Modal>
    </div>
  );
}

LegalModal.propTypes = propTypes;
LegalModal.defaultProps = defaultProps;
