// Base fragment strings
export const textPairFragmentStr = `
  fragment textPair on TextPair {
    key
    value
  }
`;

// Error Fragment
export const errorFragmentStr = `
  fragment error on Error {
    message {
      key
      value
    }
    status
  }
`;

// Form fragment strings
export const optionValuesFragmentStr = `
  fragment optionValues on Option {
    optionValue
    text {...textPair}
    description {...textPair}
  }
`;

export const inputFragmentStr = `
  fragment input on Input {
    error { ...textPair }
    label { ...textPair }
    name
    description { ...textPair }
    requiredField
  }
`;

export const multiSelectFragmentStr = `
  fragment multiselect on MultiSelect {
    description { ...textPair }
    error { ...textPair }
    label { ...textPair }
    name
    optionList { ...optionValues }
  }
`;

export const formGroupFragmentStr = `
  fragment formGroup on FormGroup {
    label { ...textPair }
    error { ...textPair }
    fieldList {
      ... on MultiSelect { ...multiselect }
      ... on Input { ...input }
    }
  }
`;

export const formFragmentStr = `
  fragment form on Form {
    id
    label { ...textPair }
    error { ...textPair }
    formGroupList { ...formGroup }
  }
`;

export const reviewItemFragmentStr = `
  fragment reviewItem on ReviewItem {
    gender
    name
    color
    size
    productId
    productUrl
    imageSrc
    shortSku
  }
`;

export const linkListValuesStr = `
  fragment linkListValues on FieldOptionList {
    label { ...textPair }
    options {
      url: optionValue
      text {...textPair}
      redirectRoute
      description {...textPair}
    }
  }
`;
