import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Button,
  IconBlock,
  VerticalSeparator,
  Icon,
} from 'anf-core-react';
import { ERROR_MESSAGE, LoadingButton } from '../Messages/Messages';
import TmntText from '../Common/Text/TmntText';
import { LOGGED_IN_BUTTON_QUERY } from './operations';

const propTypes = { onClick: PropTypes.func };
const defaultProps = { onClick: undefined };

export default function LoggedInButton({ onClick }) {
  const {
    refetch, loading, error, data,
  } = useQuery(LOGGED_IN_BUTTON_QUERY, {
    ssr: false,
    context: { batch: true },
    fetchPolicy: 'cache-and-network',
    refetchPolicy: 'network-only',
  });

  useEffect(() => {
    const refetchCallback = () => refetch();
    document.addEventListener('myInfo:updated', refetchCallback);
    return () => document.removeEventListener('myInfo:updated', refetchCallback);
  }, [refetch]);

  if (loading) return <LoadingButton />;
  if (error) return ERROR_MESSAGE;

  const { textFor = {}, config = {} } = data;

  const {
    tierImageAltText,
    welcomeMessageText,
    availableRewardsCountText,
    rewardsLabelText,
  } = textFor;

  const { tierImageSrc } = config;

  /*
   Note:
   labelText currently displays the default brand logo that comes from 'LOYALTY_LOGO_LABEL'
   The tierImageAltText is coming from tierImageSrc and the ImageAltText should actually
   read out the Image we display VIP or Abercrombie logo.
   But the labelText is returning myAbercrombie logo though there is a VIP image displayed on the UI
   (it's the same on Account Nav and current Global Nav).
  TODO: this will be updated updated based on tier in Global Nav and Account Nav
  */
  const defaultLabelText = `${welcomeMessageText.value},${tierImageAltText?.value},${rewardsLabelText.value}`;
  const buttonLabelText = availableRewardsCountText ? `${defaultLabelText},${availableRewardsCountText.value}` : defaultLabelText;

  const renderLoyaltyIconBlock = (isLoyalty) => {
    if (!isLoyalty) return null;

    const loyaltyIcon = (
      <Icon
        icon={tierImageSrc}
        isImage
        labelText={tierImageAltText?.value}
        size="xl"
      />
    );

    if (!availableRewardsCountText?.value) {
      return (
        <div style={{ display: 'flex' }}>
          { loyaltyIcon }
        </div>
      );
    }

    return (
      <IconBlock icon={loyaltyIcon}>
        <span className="h4">
          <TmntText tmnt={availableRewardsCountText} />
        </span>
      </IconBlock>
    );
  };

  const loyaltyBlock = renderLoyaltyIconBlock(tierImageSrc !== 'nonLoyalty');

  const welcomeMessage = (
    <IconBlock icon={(<Icon icon="account-circle" size="s" />)}>
      <TmntText tmnt={welcomeMessageText} />
    </IconBlock>
  );

  return (
    <div
      className="logged-in-sign-in-block"
      data-testid="logged-in-sign-in-block"
      style={{ display: 'flex' }}
    >
      <Button
        labelText={buttonLabelText}
        variant="borderless"
        onClick={onClick}
      >
        {
          !loyaltyBlock
            ? welcomeMessage
            : (
              <VerticalSeparator>
                { loyaltyBlock }
                { welcomeMessage }
              </VerticalSeparator>
            )
        }
      </Button>
    </div>
  );
}

LoggedInButton.propTypes = propTypes;
LoggedInButton.defaultProps = defaultProps;
