import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'anf-core-react';
import { useQuery } from '@apollo/client';
import DiscountCardContainer from '../Common/DiscountCard/DiscountCardContainer';
import { FormCell } from '../FormStructure';
import TmntHtml from '../Common/Text/TmntHtml';
import { REWARDS_TEXT_QUERY } from './operations';

const propTypes = {
  isMobileView: PropTypes.bool,
};

const defaultProps = {
  isMobileView: PropTypes.bool,
};

export default function RewardsAccordion({ isMobileView }) {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleOnExpansion = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    const storedState = sessionStorage.getItem('accordionPrevState');
    if (storedState) {
      setIsExpanded(JSON.parse(storedState));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('accordionPrevState', isExpanded);
  }, [isExpanded]);

  const { loading, error: queryError, data: queryData } = useQuery(REWARDS_TEXT_QUERY, {
    ssr: false,
    context: { batch: true },
    refetchPolicy: 'network-only',
  });

  if (loading) return null;
  if (queryError) return null;

  const {
    textFor = {}, config = {}, coupons = [], isProgressBarEnabled,
  } = queryData;

  const {
    redeemInBagText, exclusionsApplyLabelText, rewardsAccordionTitleText,
    estimatedRewardPointsText, nextRewardText, bdayMonthDoublePointsDesc,
    seeDetailsLabel, seeRewardsLabel,
  } = textFor;
  const { seeDetailsLinkUrl, seeAllRewardsLinkUrl } = config || {};

  const renderDiscountDetails = (text, href) => (
    <a
      target="_self"
      rel="noreferrer"
      href={href}
      style={{ display: 'block' }}
      className="open-modal-link"
    >
      {text}
    </a>
  );

  const renderSeeDetails = (estimatedRewardPoints, text) => {
    const rewardsLabel = isProgressBarEnabled
      ? <TmntHtml tmnt={nextRewardText} />
      : estimatedRewardPoints;
    return (
      <span>
        { rewardsLabel }
        <a
          target="_self"
          href={seeDetailsLinkUrl}
          className="open-modal-link"
          data-property={text}
          rel="noreferrer"
          data-testid="see-details"
          style={{ float: 'right' }}
        >
          {text}
        </a>
      </span>
    );
  };

  const renderDiscountCard = ({
    title, description, badge, expiration, exclusionLink, id,
  }) => (
    <DiscountCardContainer
      key={`discountcard_${id}`}
      discountTitle={title.value}
      discountSecondaryTitle={description?.key ? description?.value : ''}
      graphicBadge={badge}
      discountDescription={expiration.value}
      id={id}
      discountMessage={redeemInBagText.value}
      discountDetails={
        renderDiscountDetails(exclusionsApplyLabelText?.value, exclusionLink)
      }
      orientation={isMobileView ? 'stacked' : null}
    />
  );

  const renderCoupons = (nCoupons) => (
    <div className="rewards-accordion-coupons">
      {nCoupons.map((coupon) => (
        renderDiscountCard(coupon)
      ))}
    </div>
  );

  const showRewardsLink = coupons?.length >= 5;

  return (
    <FormCell>
      <Accordion
        headingLevel={2}
        id="rewards-coupons"
        title={rewardsAccordionTitleText.value}
        titleDescription={(
          <>
            {renderSeeDetails(
              estimatedRewardPointsText.value,
              seeDetailsLabel?.value,
            )}
          </>
        )}
        variant="boxed"
        isExpanded={isExpanded}
        onClick={handleOnExpansion}
      >
        <div className="rewards-bday-points-description">
          <TmntHtml tmnt={bdayMonthDoublePointsDesc} />
        </div>
        { coupons.length > 5 ? renderCoupons(coupons.slice(0, 5)) : renderCoupons(coupons) }
        {showRewardsLink ? (
          <>
            <br />
            <a
              href={seeAllRewardsLinkUrl}
              rel="noreferrer"
              data-testid="see-rewards-link"
            >
              { seeRewardsLabel?.value }
            </a>
          </>
        ) : '' }
      </Accordion>
    </FormCell>
  );
}

RewardsAccordion.propTypes = propTypes;
RewardsAccordion.defaultProps = defaultProps;
