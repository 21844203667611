import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'anf-core-react';
import SuccessContainer from './SuccessContainer';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function SuccessModal({ isOpen, onClose }) {
  return (
    <Modal
      id="success-modal"
      isOpen={isOpen}
      onClose={onClose}
      variant="headerless"
    >
      <div className="scope-1892">
        <div className="success-modal" data-testid="success-modal">
          <SuccessContainer onClose={onClose} />
        </div>
      </div>
    </Modal>
  );
}

SuccessModal.propTypes = propTypes;
