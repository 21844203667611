import React from 'react';
import PropTypes from 'prop-types';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';
import ESpot from '../ESpot/ESpot';

export default function AdditionalInfo({ userCountry, sectionTitle, sectionContent }) {
  return (
    <div
      className="additional-info-section"
      data-testid="additional-info-section"
    >
      <h2>
        <TmntText tmnt={sectionTitle} />
      </h2>
      <div className="additional-info-content">
        <TmntHtml tmnt={sectionContent} />
      </div>
      {userCountry === 'JP' && (
        <ESpot espotId="cust-giftcard-disclosure" />
      )}
    </div>
  );
}

AdditionalInfo.propTypes = {
  userCountry: PropTypes.string,
  sectionTitle: textProp.isRequired,
  sectionContent: textProp.isRequired,
};

AdditionalInfo.defaultProps = {
  userCountry: '',
};
