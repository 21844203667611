import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { LinkListArrow } from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import RewardsLink from './RewardsLink';
import { REWARDS_LINKS_LOGGEDIN_QUERY } from './operations';
import { textProp } from '../../tools/customProps';
import TmntHtml from '../Common/Text/TmntHtml';

const LinkProp = PropTypes.shape({
  text: textProp,
  url: PropTypes.string,
  description: textProp,
});

const propTypes = {
  links: PropTypes.arrayOf(LinkProp).isRequired,
  onMyAbercrombie: PropTypes.func,
  onOpenAuthModal: PropTypes.func,
  isMobileView: PropTypes.bool,
};

const defaultProps = {
  onMyAbercrombie: () => {},
  onOpenAuthModal: () => {},
  isMobileView: PropTypes.bool,
};

export default function NavigationLinks({
  links, onOpenAuthModal, onMyAbercrombie, isMobileView,
}) {
  const rewardsCenterLinks = [];
  const linkProps = [];
  const { loading, error, data } = useQuery(REWARDS_LINKS_LOGGEDIN_QUERY);
  if (loading) return null;
  if (error) return null;

  const { enableLoyaltySectionNavLinks, userState } = data;
  const { isLoggedIn } = userState;

  if (loading || error) return null;

  links.forEach(({
    text, url, description, redirectRoute = undefined,
  }) => {
    const key = text?.key;
    const tmnt = <TmntText tmnt={text} />;
    let descriptionTmnt = description?.value && enableLoyaltySectionNavLinks
      ? <TmntText tmnt={description} />
      : null;
    if (description?.key === 'LOYALTY_NEXT_REWARD_CASH') { // The TMNT returns value as HTML tag for HCO only
      descriptionTmnt = <TmntHtml tmnt={description} />;
    }

    const linkItem = {
      children: (
        <>
          {tmnt}
          {descriptionTmnt}
        </>
      ),
      key,
    };

    if (url.includes('/UserAccountView')) {
      return linkProps.push({
        ...linkItem,
        onClick: () => window.location.assign(url),
      });
    }

    if (url === 'authentication-modal:open') {
      return linkProps.push({
        ...linkItem,
        onClick: () => onOpenAuthModal({ isSignInTab: true, redirect: redirectRoute }),
      });
    }

    if (url.includes('/RewardsCenterDisplayView')) {
      return rewardsCenterLinks.push({
        ...linkItem,
        onClick: () => onMyAbercrombie(url),
      });
    }

    return linkProps.push({
      ...linkItem,
      href: url,
    });
  });
  return (
    <div className="navigation-links">
      {rewardsCenterLinks.length > 0 && <LinkListArrow links={rewardsCenterLinks} />}
      { enableLoyaltySectionNavLinks && isLoggedIn
        ? <RewardsLink isMobileView={isMobileView} /> : null }
      <LinkListArrow links={linkProps} />
    </div>
  );
}

NavigationLinks.propTypes = propTypes;
NavigationLinks.defaultProps = defaultProps;
