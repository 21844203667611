import { gql } from '@apollo/client';
import { textPairFragment } from '../../query/fragments';

export const UPPER_FUNNEL_LOGIN_CLIENT_QUERY = gql`
  query UPPER_FUNNEL_LOGIN_CLIENT_QUERY {
    userState { isLoggedIn }
  }
`;

export const UPPER_FUNNEL_CONFIG_QUERY = gql`
  query UPPER_FUNNEL_CONFIG_QUERY {
    config {
      hasUpperFunnelLoginEnabled: bool(name: "hasUpperFunnelLoginEnabled")
    }
  }
`;

export const UPPER_FUNNEL_USER_STATE_QUERY = gql`
  query UPPER_FUNNEL_USER_STATE_QUERY {
    userState { isGeneric }
  }
`;

export const UPPER_FUNNEL_CONTENT_QUERY = gql`
  query UPPER_FUNNEL_CONTENT_QUERY {
    textFor {
      upperFunnelToasterContent: pair(pairKey: "upperFunnelToasterContent") { ...textPair }
    }
  }

  ${textPairFragment}
`;

export const UPPER_FUNNEL_TOASTER_QUERY = gql`
  query UPPER_FUNNEL_TOASTER_QUERY {
    textFor {
      signInOrJoinText: pair(pairKey: "signInOrJoin") { ...textPair }
      closeButtonLabel: pair(pairKey: "signInToasterCloseButtonText") { ...textPair }
    }
    config {
      ufLoginPromptTimeout: int(name: "UFLoginPromptTimeout")
    }
    xSeg @client
  }

  ${textPairFragment}
`;
