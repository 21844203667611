import { RE2JS } from 're2js';

// Amex - 15-digit long, with no alterations and spaced in three
// blocks of 4, 6 and 5 digits from left to right: “3xxx xxxxxx xxxxx”
export function formatCardNumber(cardNumber, cardBrand) {
  if (cardBrand === '003') {
    if (cardNumber.length > 4 && cardNumber.length <= 10) {
      return cardNumber.replace(/(\d{1,4})/, '$1 ');
    } if (cardNumber.length > 10 && cardNumber.length < 15) {
      return cardNumber.replace(/(\d{1,4})(\d{1,6})/, '$1 $2 ');
    } if (cardNumber.length >= 15) {
      return cardNumber.replace(/\b(\d{4})(\d{6})(\d{5})\b/, '$1 $2 $3');
    }
    return cardNumber;
  }

  const parts = [];
  const tempFormat = cardNumber
    .replace(/\s+/g, '')
    .replace(/[^0-9]/gi, '')
    .substring(0, 16);

  for (let i = 0; i < tempFormat.length; i += 4) {
    parts.push(tempFormat.substr(i, 4));
  }
  return parts.length > 1 ? parts.join(' ') : cardNumber;
}

export function getCardBrand(cardNumber, regexArr) {
  // Look through regexArr and return the index of the KV pair
  // where the cardNumber matches the regex
  const i = regexArr.findIndex((regexObj) => {
    const regexStrValue = regexObj.regexValue?.source;
    const isMatch = RE2JS.compile(regexStrValue).matcher(cardNumber).lookingAt();
    return isMatch;
  });

  const matchingCardRegex = regexArr[i];

  return (matchingCardRegex && matchingCardRegex.brand) ? matchingCardRegex.brand : '021';
}

export function getCardBrandIcon(cardBrand = '021') {
  return ({
    '001': 'visa',
    '002': 'mastercard',
    '003': 'amex',
    '004': 'discover',
    '007': 'jcb',
    '021': 'default-card',
  })[cardBrand];
}

export function getCardMaxLength(cardBrand) {
  if (cardBrand === '003') {
    return 17;
  }
  return 19;
}

export function maskCardNumber(cardBrand, cardNumber, obscureChar) {
  let lastDigits;

  if (cardNumber.includes(obscureChar)) {
    return cardNumber;
  }

  if (cardBrand && cardNumber && obscureChar) {
    lastDigits = cardNumber.replace(/ /g, '').slice(-4);
    switch (cardBrand) {
      case '003':
        return `${obscureChar + obscureChar + obscureChar + obscureChar} ${
          obscureChar}${obscureChar}${obscureChar}${obscureChar}${obscureChar}${
          obscureChar} ${obscureChar}${lastDigits}`;
      default:
        return `${obscureChar + obscureChar + obscureChar + obscureChar} ${
          obscureChar}${obscureChar}${obscureChar}${obscureChar} ${
          obscureChar}${obscureChar}${obscureChar}${obscureChar} ${lastDigits}`;
    }
  }
  return cardNumber; // default - lint wanted something here
}

export function formatExpirationDate(expirationDate) {
  if (!expirationDate) {
    return expirationDate;
  }
  if (expirationDate && expirationDate.length > 2 && (expirationDate !== 'undefined' || expirationDate !== undefined)) {
    return expirationDate
      .replace(/\//g, '')
      .substring(0, 2)
      + (expirationDate.length > 2 ? '/' : '')
      + expirationDate
        .replace(/\//g, '')
        .substring(2, 4);
  }
  return expirationDate;
}

export function isCardNumberInvalid(cardNumber, cardBrand) {
  if (
    !cardNumber
    || (cardBrand === '003' && cardNumber.length < 15)
    || (cardBrand !== '003' && cardNumber.length < 16)
  ) {
    return true;
  }

  return false;
}

export function isExpDateInvalid(expDate) {
  if (!expDate || expDate.length < 4) return true;

  const today = new Date();
  const someday = new Date();
  const month = expDate.substring(0, 2);
  const year = expDate.substring(2, 4);
  someday.setFullYear(`20${year}`, month);

  if (someday < today) return true;

  return false;
}

export function findBadParams(responseErrors) {
  if (!responseErrors) {
    return { isCardNumberInvalid: false, isCardExpDateInvalid: false };
  }

  return responseErrors.reduce((acc, { extensions = {} }) => {
    const { response } = extensions;

    if (response?.body?.error) {
      response.body.error.forEach((errorItem) => {
        if (errorItem?.errorKey === 'AUTH_MESSAGE1006') {
          acc.isCardExpDateInvalid = true;
        }
      });
    }

    if (response?.body?.Code === '4009540') {
      acc.isCardNumberInvalid = true;
    }

    return acc;
  }, { isCardNumberInvalid: false, isCardExpDateInvalid: false });
}
