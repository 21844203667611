import React, { useState, useContext } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, AccountBlock } from 'anf-core-react';
import { Column, Row } from '../Common/Grid';
import TmntText from '../Common/Text/TmntText';
import { LOADING_MESSAGE, ERROR_MESSAGE } from '../Messages/Messages';
import { AddressContext, AddressContextProvider } from './AddressContextProvider';
import AddressCard from './AddressCard';
import AddressModal from './AddressModal/AddressModal';
import { ADDRESS_PAGE_QUERY, SET_DEFAULT_ADDRESS_MUTATION } from './addressOperations';

export default function AddressPage() {
  const { textFor } = useContext(AddressContext);
  const [openModal, setOpenModal] = useState(false);
  const [addressIdToEdit, setAddressIdToEdit] = useState(undefined);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
  } = useQuery(ADDRESS_PAGE_QUERY);

  const [setDefaultAddress] = useMutation(SET_DEFAULT_ADDRESS_MUTATION, {
    refetchQueries: ['GetAddressPageData'],
  });

  if (pageLoading) return LOADING_MESSAGE;
  if (pageError) return ERROR_MESSAGE;

  const { config } = pageData;

  const handleOpenModal = () => { setOpenModal(true); };

  const handleCloseModal = () => {
    setOpenModal(false);
    setAddressIdToEdit(undefined);
  };

  const handleEditAddress = (addressId) => {
    setAddressIdToEdit(addressId);
    handleOpenModal();
  };

  const handleSetDefaultAddress = async (addressId) => (
    setDefaultAddress({ variables: { id: addressId } })
  );

  const renderAddresses = ({ addresses, defaultId }) => addresses.map((address) => {
    const isDefault = defaultId === address.id;

    return (
      <div key={`addresscard_${address.id}`} className="address-cell a-xs-8 a-md-4">
        <AddressCard
          address={address}
          isDefault={isDefault}
          onEditAddress={handleEditAddress}
          onSetDefault={handleSetDefaultAddress}
        />
      </div>
    );
  });

  const renderEmptyAddresses = () => (<TmntText tmnt={textFor?.pageEmptyDesc} />);

  const renderAddressContent = () => {
    const noSavedAddresses = pageData?.savedAddresses.addresses.length < 1;
    return (noSavedAddresses) ? renderEmptyAddresses() : renderAddresses(pageData?.savedAddresses);
  };

  return (
    <>
      <AccountBlock
        heading={(
          <h3 className="h3" data-testid="address-page-header">
            <TmntText tmnt={textFor?.pageTitle} />
          </h3>
        )}
      >
        <Row>
          {renderAddressContent()}
          <Column xs="8">
            <hr className="hr" />
          </Column>
          <Column xs="8" md="3">
            <Button
              classList="new-address-button"
              variant="secondary"
              onClick={handleOpenModal}
              isFullWidth
            >
              <TmntText tmnt={textFor?.buttonAddNewAddress} />
            </Button>
          </Column>
        </Row>
      </AccountBlock>
      <AddressModal
        addressId={addressIdToEdit}
        countryId={config?.defaultCountryByRegion}
        isOpen={openModal}
        onClose={handleCloseModal}
      />
    </>
  );
}

export function AddressPageClient() {
  return (
    <AddressContextProvider>
      <AddressPage />
    </AddressContextProvider>
  );
}
