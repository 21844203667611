import { gql } from '@apollo/client';
import { textPairFragment } from '../../query/fragments';

export const SUCCESS_MODAL_QUERY = gql`
  query SuccessModal {
    textFor {
      loyaltyTierImageAltText: pair(pairKey: "loyaltyTierLogoLabel") {...textPair }
      successModalHeader: pair(pairKey: "successModalHeader") {...textPair}
      successModalBody: pair(pairKey: "successModalBody") {...textPair}
      successModalButtonText: pair(pairKey: "successModalButtonText") {...textPair}
      redeemInBagText: pair(pairKey: "redeemInBag") {...textPair}
      exclusionsApplyLabelText: pair(pairKey: "exclusionsApplyLabel") {...textPair}
    }
    config {
      loyaltyWelcomeMessageImageUrl: nonCachedString(name: "loyaltyWelcomeMessageImageUrl")
    }
    coupons {
      ... on Reward {
        id
        title {...textPair}
        description {...textPair}
        badge
        expiration {...textPair}
        exclusionLink
      }
      ... on Promotion {
        id
        title {...textPair}
        description {...textPair}
        badge
        expiration {...textPair}
        exclusionLink
      }
    }
  }

  ${textPairFragment}
`;
