import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import AuthenticationModal from './AuthenticationModal';
import LoggedInButton from './LoggedInButton';
import SignInButton from './SignInButton';
import SignInToaster from './SignInToaster';
import SignInMenu from './SignInMenu';
import { SignInToasterContext } from '../../context/ModalContext';
import { DESKTOP_SIGN_IN_QUERY } from './operations';
import SignInRestructure from './SignInRestructure';
import { renderDesktopSignInLoading, renderDesktopSignInContainer } from './helpers';
import SignInBlock from './SignInBlock';

function DesktopSignIn() {
  const { openModal: openToaster } = useContext(SignInToasterContext);

  const { loading, data = {} } = useQuery(
    DESKTOP_SIGN_IN_QUERY,
    {
      context: { batch: true },
      fetchPolicy: 'cache-and-network',
      ssr: false,
    },
  );

  if (loading) return renderDesktopSignInLoading();

  const { userState = {} } = data;
  const { isLoggedIn = false } = userState;

  return renderDesktopSignInContainer(
    (
      <>
        {
          !isLoggedIn
            ? <SignInButton onClick={openToaster} />
            : <LoggedInButton onClick={openToaster} />
        }
        <AuthenticationModal />
        <SignInToaster>
          <SignInMenu isLoggedIn={isLoggedIn} />
        </SignInToaster>
      </>
    ),
  );
}

/*
  Note: temporary setup to encapsulate the sign in block around
  the sign in restructure flag
*/
export default function DesktopSignInClient() {
  return (
    <SignInRestructure
      loadingElement={renderDesktopSignInLoading()}
      hiddenElement={<SignInBlock />}
      switchKey="desktop"
    >
      <DesktopSignIn />
    </SignInRestructure>
  );
}
