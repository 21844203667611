import React from 'react';
import hydrate from '../lib/hydrate';
import DesktopSignIn from '../lib/components/SignIn/DesktopSignIn';
import {
  AuthenticationModalContext,
  ModalContextProvider,
  SignInToasterContext,
} from '../lib/context/ModalContext';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';

function DesktopSignInFrontend() {
  return (
    <ModalContextProvider context={AuthenticationModalContext}>
      <ModalContextProvider context={SignInToasterContext}>
        <AuthenticationFormContextProvider>
          <DesktopSignIn />
        </AuthenticationFormContextProvider>
      </ModalContextProvider>
    </ModalContextProvider>
  );
}

hydrate({
  frontend: 'DesktopSignInFrontend',
  component: DesktopSignInFrontend,
  persist: true,
});
