import PropTypes from 'prop-types';

export const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  children: PropTypes.node,
  control: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  defaultType: PropTypes.oneOf(['password', 'text']),
  hideLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onTypeChange: PropTypes.func,
  showLabel: PropTypes.string,
  inputValue: PropTypes.string,
};

export const defaultProps = {
  autoComplete: 'on',
  children: undefined,
  defaultType: 'password',
  hideLabel: undefined,
  isDisabled: false,
  isInvalid: false,
  isRequired: false,
  maxLength: null,
  onChange: undefined,
  onFocus: undefined,
  onTypeChange: undefined,
  showLabel: undefined,
  inputValue: undefined,
};
