import { gql, useQuery } from '@apollo/client';
import { Pagination, TabPanel, Tabs } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import {
  labelValuePairFragment,
  orderHistoryItemFragment,
  textPairFragment,
  transactionSummaryFragment,
} from '../../query/fragments';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import RecommendationsContainer from '../Recommendations/RecommendationsContainer';
import OrderHistoryBlock from './OrderHistoryBlock';

const PAGESIZE = 5;

const queryOrderHistory = gql`
  query OrderHistoryQuery($channel : String!, $pageNumber : Int, $pageSize : Int) {
    orders: orderHistory(channel : $channel, pageNumber: $pageNumber, pageSize: $pageSize) {
      orderHistoryItems {...order}
      isLastPage
    }
  }

  ${textPairFragment}
  ${labelValuePairFragment}
  ${transactionSummaryFragment}
  ${orderHistoryItemFragment}
`;

const queryOrderHistoryText = gql`
  query OrderHistoryTextQuery {
    textFor {
      online: pair(pairKey: "orderHistoryOnlineText") { ...textPair }
      instore: pair(pairKey: "orderHistoryInstoreText") { ...textPair }
      empty: pair(pairKey: "orderHistoryEmptyText") { ...textPair }
      next: pair(pairKey: "next") { ...textPair }
      previous: pair(pairKey: "previous") { ...textPair }
      page: pair(pairKey: "page") { ...textPair }
    }
  }

  ${textPairFragment}
`;

const CHANNELS = { online: 'dtc', store: 'store' };
const EVENTS = { update: 'orderHistory:update' };

let textCache;

const propTypes = {
  orientation: PropTypes.string,
};
const defaultProps = {
  orientation: 'horizontal',
};
export default function OrderHistory({ orientation }) {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('pagefm', 'navigation - account flyout');
    window.history.replaceState(null, '', `${window.location.pathname}?${params.toString()}`);
    window.digitalData?.set?.('page.pageDescription', 'purchase history');
  }, []);

  const [selectedTabId, setSelectedTabId] = useState(CHANNELS.online);
  const { loading, error, data: textData } = useQuery(queryOrderHistoryText);
  const [pageNumber, setPageNumber] = useState(1);
  const [onlinePageNumber, setOnlinePageNumber] = useState(1);
  const [storePageNumber, setStorePageNumber] = useState(1);
  const [componentDidMount, setComponentDidMount] = useState(false);
  const tabRefs = [
    useRef(null),
    useRef(null),
  ];

  const {
    loading: historyLoading,
    error: historyError,
    data: historyData,
    refetch: refetchHistory,
  } = useQuery(queryOrderHistory, {
    variables: { channel: selectedTabId, pageNumber, pageSize: PAGESIZE },
    context: { batch: true },
    ssr: false,
  });

  useEffect(() => {
    setComponentDidMount(true);
  }, []);

  useEffect(() => {
    setPageNumber(selectedTabId === CHANNELS.online ? onlinePageNumber : storePageNumber);
    const refetch = () => refetchHistory({ channel: selectedTabId });
    document.addEventListener(EVENTS.update, refetch);
    return () => document.removeEventListener(EVENTS.update, refetch);
  }, [refetchHistory, selectedTabId, onlinePageNumber, storePageNumber]);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  textCache = textData.textFor;

  const renderCenteredBlock = (message) => (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '200px',
    }}
    >
      { message }
    </div>
  );

  const renderBlock = ({
    isEmpty,
    heading,
    status,
    number,
    detailsLink,
    ctaGroup,
    summary,
  }, index) => (
    <OrderHistoryBlock
      isEmpty={isEmpty}
      heading={heading}
      status={status}
      number={number}
      key={`${number}_${index}`}
      detailsLink={detailsLink}
      ctaGroup={ctaGroup}
      summary={summary}
      emptyContent={renderCenteredBlock(<TmntText tmnt={textCache.empty} />)}
    />
  );

  const renderBlocks = () => (
    !historyLoading && historyData
      ? historyData.orders.orderHistoryItems.map((order, i) => renderBlock(order, i))
      : renderCenteredBlock(LOADING_MESSAGE)
  );

  const incrementPage = () => {
    if (selectedTabId === CHANNELS.online) {
      setOnlinePageNumber((prevPageNumber) => prevPageNumber + 1);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    } else {
      setStorePageNumber((prevPageNumber) => prevPageNumber + 1);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const decrementPage = () => {
    if (selectedTabId === CHANNELS.online) {
      setOnlinePageNumber((prevPageNumber) => prevPageNumber - 1);
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    } else {
      setStorePageNumber((prevPageNumber) => prevPageNumber - 1);
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
    }
  };

  const renderPanel = () => (!historyError ? renderBlocks() : renderCenteredBlock(ERROR_MESSAGE));
  const handleTabClick = (anEvent) => {
    setSelectedTabId(anEvent.currentTarget.getAttribute('id'));
  };

  const onKeyDownTab = (anEvent) => {
    const visibleTabId = anEvent.nativeEvent.target.id;
    const directionalArrowCodes = orientation === 'vertical'
      ? ['ArrowDown', 'ArrowUp']
      : ['ArrowLeft', 'ArrowRight'];

    if (directionalArrowCodes.includes(anEvent.code)) {
      anEvent.preventDefault();
      const keys = Object.values(CHANNELS);
      const currentIndex = keys.indexOf(visibleTabId);
      const deltasByCode = {
        ArrowDown: 1,
        ArrowLeft: -1,
        ArrowRight: 1,
        ArrowUp: -1,
      };

      const indexOfTabToShow = (
        keys.length
        + currentIndex
        + deltasByCode[anEvent.code]
      ) % keys.length;

      setSelectedTabId(keys[indexOfTabToShow]);
      tabRefs[indexOfTabToShow].current.focus();
    }
  };

  const recommendationsProps = {
    clientId: 'orderHistory',
    placements: [
      'generic_page.orderHistory1',
      'generic_page.orderHistory2',
      'generic_page.orderHistory3',
      'generic_page.orderHistory4',
      'generic_page.orderHistory5',
      'generic_page.orderHistory6',
    ],
  };

  return (
    <div className="order-history">
      <Tabs
        controls={{
          tabs: [
            {
              ariaControls: 'online-tabpanel',
              id: CHANNELS.online,
              isSelected: selectedTabId === CHANNELS.online,
              label: textCache.online?.value,
              ref: tabRefs[0],
              onClick: handleTabClick,
            },
            {
              ariaControls: 'instore-tabpanel',
              id: CHANNELS.store,
              isSelected: selectedTabId === CHANNELS.store,
              label: textCache.instore?.value,
              ref: tabRefs[1],
              onClick: handleTabClick,
            },
          ],
          onKeyDown: onKeyDownTab,
        }}
        labelText="OrderHistoryTabs"
        orientation={orientation}
      >
        <TabPanel
          ariaLabelledBy={CHANNELS.online}
          isVisible={selectedTabId === CHANNELS.online}
          id={CHANNELS.online}
        >
          {selectedTabId === CHANNELS.online ? renderPanel() : <span />}
        </TabPanel>
        <TabPanel
          ariaLabelledBy={CHANNELS.store}
          id={CHANNELS.store}
          isVisible={selectedTabId === CHANNELS.store}
        >
          {selectedTabId === CHANNELS.store ? renderPanel() : <span />}
        </TabPanel>
      </Tabs>
      {componentDidMount && (
        <Pagination
          nextButton={{
            labelText: textCache.next?.value,
            onClick: incrementPage,
            isDisabled: (historyData && historyData.orders.isLastPage) || historyLoading,
          }}
          previousButton={{
            labelText: textCache.previous?.value,
            onClick: decrementPage,
            isDisabled: pageNumber === 1 || historyLoading,
          }}
          variant="abbreviated"
        >
          <TmntText tmnt={textCache.page} />
          <span>
            &nbsp;
            {pageNumber}
          </span>
        </Pagination>
      )}
      <RecommendationsContainer
        clientId={recommendationsProps.clientId}
        placements={recommendationsProps.placements}
      />
    </div>
  );
}

OrderHistory.defaultProps = defaultProps;
OrderHistory.propTypes = propTypes;
