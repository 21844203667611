import React from 'react';
import hydrate from '../lib/hydrate';
import { LegalModalEventWrapper } from '../lib/components';

function LegalModalEventWrapperFrontend() {
  return (
    <LegalModalEventWrapper />
  );
}

hydrate({
  frontend: 'LegalModalEventWrapperFrontend',
  component: LegalModalEventWrapperFrontend,
});
