import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { softAuththenticationErrorVar } from './context/ApplicationContext/ApplicationContext';
import { SessionContextProvider } from './context/SessionContext/SessionContext';

const propTypes = {
  children: PropTypes.node.isRequired,
  frontend: PropTypes.string.isRequired,
  clientId: PropTypes.string,
  persist: PropTypes.bool,
  useGlobalContextKey: PropTypes.bool,
};

const defaultProps = {
  clientId: undefined,
  persist: false,
  useGlobalContextKey: true,
};

const authModalOpenEventName = 'authentication-modal:open';

export default function App({
  children,
  clientId,
  frontend,
  persist,
  useGlobalContextKey,
}) {
  const softAuthError = useReactiveVar(softAuththenticationErrorVar);

  useEffect(() => {
    if (!softAuthError) return () => {};

    document.dispatchEvent(new CustomEvent(authModalOpenEventName, {
      detail: { isSignIn: true, isRememberedCheckRequired: true },
    }));

    return () => { softAuththenticationErrorVar(null); };
  }, [softAuthError]);

  if (!persist) return children;

  return (
    <SessionContextProvider
      frontend={frontend}
      clientId={clientId}
      useGlobalContextKey={useGlobalContextKey}
    >
      { children }
    </SessionContextProvider>
  );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;
