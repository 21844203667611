import { useDatabridge } from '@xp-utilities/web';
import parsePhoneNumber, { AsYouType, getCountryCallingCode } from 'libphonenumber-js';

export default function usePhoneNumberValidation() {
  const [getCountry] = useDatabridge('page.country', 'US');

  const validate = (phoneNumber) => {
    const country = getCountry() || 'US';

    // get the numerical country calling code for the given country code
    const countryCallingCode = getCountryCallingCode(country);

    if (!phoneNumber) return { isValid: false, formattedPhoneNumber: `+${countryCallingCode} ` };

    // currently validating using isPossbile() method from libphonenumber-js
    // which checks  if the number is possible but not necessarily valid (e.g. length check)
    // alternativley we could use isValid() to check if the actual phone number digits AND length
    // are valid for the given country
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber, country);
    const isPossiblePhoneNumber = parsedPhoneNumber?.isPossible() || false;

    // format phone number as user types
    const formattedPhoneNumber = new AsYouType()?.input(phoneNumber);

    return {
      isValid: isPossiblePhoneNumber,
      formattedPhoneNumber,
    };
  };

  return validate;
}
