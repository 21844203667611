import React from 'react';
import {
  AddToBagRecommendationsContextProvider,
  CategoryRecommendationsContextProvider,
  GenericRecommendationsContextProvider,
  MyListRecommendationsContextProvider,
  NullSearchRecommendationsContextProvider,
  PDPRecommendationsContextProvider,
  PickedForYouRecommendationsContextProvider,
  QuickviewRecommendationsContextProvider,
  SearchRecommendationsContextProvider,
  useDatabridgeSubscribe,
} from '@xp-utilities/web';
import {
  RecommendationsWrapperPropTypes,
  RecommendationsWrapperDefaultProps,
} from './props';
import { PAGE_KINDS } from '../../tools/constants';

export const INSTANCE_DATA_ATTRIBUTE = 'data-recommendations-client';

export default function RecommendationsWrapper({ children, clientId }) {
  const pageKind = useDatabridgeSubscribe('page.pageKind');
  const isCustomerRecsEnabled = useDatabridgeSubscribe('flag.customer-standalone-recs-switches.pdp', false);

  const renderContainer = (content = children) => (
    <div
      id={clientId}
      data-testid={clientId}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ [INSTANCE_DATA_ATTRIBUTE]: clientId }}
    >
      { content }
    </div>
  );

  if (clientId === 'quickview-1') {
    return (
      <QuickviewRecommendationsContextProvider id={clientId}>
        { renderContainer() }
      </QuickviewRecommendationsContextProvider>
    );
  }

  if (clientId === 'addToBag-2') {
    return (
      <AddToBagRecommendationsContextProvider id={clientId}>
        { renderContainer() }
      </AddToBagRecommendationsContextProvider>
    );
  }

  if (clientId === 'null-search-1') {
    return (
      <NullSearchRecommendationsContextProvider>
        { renderContainer() }
      </NullSearchRecommendationsContextProvider>
    );
  }

  if (clientId === 'search-1') {
    return (
      <SearchRecommendationsContextProvider>
        { renderContainer() }
      </SearchRecommendationsContextProvider>
    );
  }

  if (clientId === 'account-flyout-1') {
    return (
      <GenericRecommendationsContextProvider>
        { renderContainer() }
      </GenericRecommendationsContextProvider>
    );
  }

  switch (pageKind) {
    // Note: add more pageKinds as needed per context
    case PAGE_KINDS.pickedForYou:
      return (
        <PickedForYouRecommendationsContextProvider>
          { renderContainer() }
        </PickedForYouRecommendationsContextProvider>
      );
    case PAGE_KINDS.category:
      return (
        <CategoryRecommendationsContextProvider id={clientId}>
          { renderContainer() }
        </CategoryRecommendationsContextProvider>
      );
    case PAGE_KINDS.myList:
      return (
        <MyListRecommendationsContextProvider>
          { renderContainer() }
        </MyListRecommendationsContextProvider>
      );
    case PAGE_KINDS.product:
      // Note: render the container only so it's available for hydration
      if (!isCustomerRecsEnabled) return renderContainer(null);

      return (
        <PDPRecommendationsContextProvider id={clientId}>
          { renderContainer() }
        </PDPRecommendationsContextProvider>
      );
    default:
      if (pageKind === null) return renderContainer(null);
      return (
        <GenericRecommendationsContextProvider>
          { renderContainer() }
        </GenericRecommendationsContextProvider>
      );
  }
}

RecommendationsWrapper.propTypes = RecommendationsWrapperPropTypes;
RecommendationsWrapper.defaultProps = RecommendationsWrapperDefaultProps;
