import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  Icon, IconBlock,
} from 'anf-core-react';
import { ERROR_MESSAGE, LoadingButton } from '../../Messages/Messages';
import { TmntText } from '../Text';
import { LOGGED_IN_LEFT_NAV_NON_CACHED_QUERY } from './operations';

export default function LeftNavProfileWelcomeMessage() {
  const {
    refetch,
    loading,
    error: nonCachedError,
    data: nonCachedData,
  } = useQuery(LOGGED_IN_LEFT_NAV_NON_CACHED_QUERY, {
    ssr: false,
    context: { batch: true },
    refetchPolicy: 'network-only',
  });

  useEffect(() => {
    const refetchCallback = () => refetch();
    document.addEventListener('myInfo:updated', refetchCallback);
    return () => document.removeEventListener('myInfo:updated', refetchCallback);
  }, [refetch]);

  if (loading) return <LoadingButton />;
  if (nonCachedError) return ERROR_MESSAGE;

  const { textFor: nonCachedTextFor, config: nonCachedConfig } = nonCachedData;
  const { welcomeMessageText, memberSinceText } = nonCachedTextFor;

  return (
    <div className="acct-welcome-msg">
      <IconBlock
        orientation="vertical"
        icon={(
          <Icon
            icon={nonCachedConfig.loyaltyTierImageSrc}
            isImage
            labelText={nonCachedTextFor.loyaltyTierImageAltText.value}
            size="l"
          />
            )}
      >
        <div className="h1">
          <TmntText tmnt={welcomeMessageText} />
        </div>
      </IconBlock>
      <div className="acct-welcome-msg__join-date">
        <TmntText tmnt={memberSinceText} />
      </div>
    </div>
  );
}
