import React, { useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { useQuery } from '@apollo/client';
import { Link as DSLink } from 'anf-core-react';
import LeftNavProfileWelcomeMessage from './LeftNavProfileWelcomeMessage';
import { ERROR_MESSAGE } from '../../Messages/Messages';
import { TmntText } from '../Text';
import { LOGGED_IN_LEFT_NAV_QUERY } from './operations';

export default function LoggedInLeftNav() {
  const [isMobile, setIsMobile] = useState(false);
  const [pathname, setPathname] = useState('');
  const {
    refetch, data, loading, error,
  } = useQuery(LOGGED_IN_LEFT_NAV_QUERY, {
    ssr: false,
    context: { batch: true },
    refetchPolicy: 'network-only',
  });

  useEffect(() => {
    const refetchCallback = () => refetch();
    document.addEventListener('myInfo:updated', refetchCallback);
    return () => document.removeEventListener('myInfo:updated', refetchCallback);
  }, [refetch]);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    const newIsMobile = windowWidth < 1025;
    if (newIsMobile !== isMobile) setIsMobile(newIsMobile);
  }, [windowWidth, isMobile]);

  useEffect(() => {
    const { location } = window;
    setPathname(location?.pathname);
  }, []);

  if (loading) return null;
  if (error) return ERROR_MESSAGE;

  const { textFor } = data;

  const checkIfSelected = (tabUrl) => {
    let isSelected = tabUrl.includes(pathname);
    if (tabUrl.includes('help')) {
      isSelected = tabUrl === pathname;
    }
    return isSelected ? 'acct-tabs__link--selected' : '';
  };

  const renderLink = (link) => {
    const { text, url } = link;

    return (
      <DSLink
        classList={`acct-tabs__link ${checkIfSelected(url)}`}
        href={url}
        key={url}
      >
        <TmntText classNames="acct-tabs__link-text" tmnt={text} />
      </DSLink>
    );
  };

  return (
    <div
      className="acct-left-nav account-left-nav--logged-in"
      data-testid="user-nav"
    >
      <div className="acct-left-nav__content">
        <LeftNavProfileWelcomeMessage />
      </div>
      <div className="acct-left-nav__content acct-left-nav__content--full-width">
        {/* necessary for overflowing tabs scroll experience on mobile */}
        <div className="acct-left-nav__tabs-container">
          <nav className="acct-tabs" data-is-mobile={isMobile}>
            { textFor.navLinks.options.map(renderLink) }
          </nav>
        </div>
      </div>
    </div>
  );
}
