import {
  xSegVar, countryVar, userTypeVar, signifydIdVar,
} from '../lib/context/ApplicationContext/ApplicationContext';
import getFrontendId from './getFrontendId';

export const getCacheId = (frontend, id) => {
  if (!id) return `${getFrontendId(frontend)}-config`;
  return `${getFrontendId(frontend, id)}-config`;
};

/*
  Note: removing use of defaultMerge because equivelent statement can be done by just using
  `merge: true`
  Found here: https://www.apollographql.com/docs/react/caching/cache-field-behavior#merging-non-normalized-objects
  ***
*/
export const getInMemoryCacheSettings = () => ({
  typePolicies: {
    TextFor: { merge: true },
    ConfigValue: { merge: true },
    UserState: {
      merge: true,
      keyFields: ({ sessionId } = {}) => {
        if (!sessionId) return null;
        return `UserState:${sessionId}`;
      },
    },
    OrderHistoryItem: { keyFields: ['number'] },
    UserInfo: { merge: true },
    Query: {
      fields: {
        featureFlag: { keyArgs: ['key'] },
        xSeg: {
          read() { return xSegVar(); },
        },
        userCountry: {
          read() { return countryVar(); },
        },
        userType: {
          read() { return userTypeVar(); },
        },
        signifydId: {
          read() { return signifydIdVar(); },
        },
      },
    },
  },
});
