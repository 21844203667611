import React, {
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Button,
  CountrySelector,
  ErrorMessage,
  FieldGroup,
  FlagBlock,
  Icon,
  Link,
} from 'anf-core-react';
import * as FormStructure from '../../FormStructure';
import { Form, FormGroup } from '../../Common/Form';
import {
  getFormData,
  getInvalidFieldsFromFormData,
  isDisabledField,
  isInvalidField,
  isRequiredField,
  getFieldMaxLengthFromMap,
  getValueFromData,
  getOnChange,
  findBadParams,
  formatOptionList,
} from '../addressUtilities';
import {
  ADDRESS_FORM_QUERY,
  GET_CITIES_QUERY,
  GET_DISTRICTS_QUERY,
} from '../addressOperations';
import {
  REQUIRED_ADDRESS_FORM_FIELDS, ADDRESS_FORM_MAX_LENGTHS, COUNTRIES_WITH_LOCALITY_QUERIES,
} from '../addressConstants';
import { LOADING_MESSAGE, ERROR_MESSAGE } from '../../Messages/Messages';
import ButtonState from '../../Common/ButtonState/ButtonState';
import TmntText from '../../Common/Text/TmntText';
import AddressField from './AddressField';
import { AddressContext } from '../AddressContextProvider';
import { addressProps } from '../addressPropTypes';
import useLegalLinksEvent from '../../../hooks/useLegalLinksEvents';
import { ModalContextProvider } from '../../../context/ModalContext';
import LegalModal from '../../Common/LegalModalBlock/LegalModal';
import LegalButton from '../../Common/LegalModalBlock/LegalButton';

const propTypes = {
  countryId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  address: addressProps,
};

const defaultProps = {
  address: undefined,
};

export const EVENTS = {
  countrySelector: {
    updated: 'ds-modal-billing-country_selection:updated',
    open: 'ds-modal-billing-country_selection:open',
  },
};

export default function AddressForm({
  address,
  countryId,
  onClose,
  onSubmit,
  onRemove,
}) {
  const { textFor } = useContext(AddressContext);
  const isEdit = address !== undefined;
  const formRef = useRef(null);
  const stateRef = useRef(null);
  const [country, setCountry] = useState();
  const [isInvalid, setIsInvalid] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [locations, setLocations] = useState({});

  // Hooks
  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery(ADDRESS_FORM_QUERY, {
    variables: { country: address?.country || countryId },
    onCompleted: ({ countryConfig }) => { setCountry(countryConfig.id); },
  });

  const [getCities] = useLazyQuery(GET_CITIES_QUERY, {
    onCompleted: ({ config }) => {
      setLocations({ city: config.cities });
    },
  });

  const [getDistricts] = useLazyQuery(GET_DISTRICTS_QUERY, {
    onCompleted: ({ config }) => {
      setLocations((prevLocations) => ({ ...prevLocations, district: config.districts }));
    },
  });

  const prefetchLocalityData = async (addressData) => {
    const findValueForKey = (object, keys) => {
      const foundKey = keys.find((key) => (
        key in object && object[key]
      ));
      return foundKey ? object[foundKey] : undefined;
    };

    const addressCountry = findValueForKey(addressData, ['country']);

    if (!COUNTRIES_WITH_LOCALITY_QUERIES.includes(addressCountry)) return Promise.resolve();

    const state = findValueForKey(addressData, ['province', 'zone']);
    const city = findValueForKey(addressData, ['city']);

    return Promise.all([
      getCities({ variables: { country: addressCountry, state } }),
      getDistricts({ variables: { country: addressCountry, state, city } }),
    ]);
  };

  const handleOnCountryChangeClick = () => document.dispatchEvent(
    new Event(EVENTS.countrySelector.open),
  );

  const legalLinkRef = useRef(null);
  const [legalTmntKey, setLegalTmntKey] = useState(null);
  useLegalLinksEvent(legalLinkRef, (value) => {
    setLegalTmntKey(value);
  });

  useEffect(() => {
    if (address) prefetchLocalityData(address);
  }, [address]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleOnCountryChange = ({ detail }) => {
      setLocations({});
      stateRef.current = null;
      refetch({ country: detail?.country });
    };

    document.addEventListener(EVENTS.countrySelector.updated, handleOnCountryChange);

    return () => {
      document.removeEventListener(EVENTS.countrySelector.updated, handleOnCountryChange);
    };
  }, [refetch]);

  const getAddressInput = () => ({ country, ...getFormData(formRef.current) });

  const handleOnError = (err) => {
    const { graphQLErrors } = err;

    const badParams = findBadParams(graphQLErrors, REQUIRED_ADDRESS_FORM_FIELDS);
    setInvalidFields([...badParams]);

    throw err;
  };

  const validate = () => {
    const fields = getAddressInput();
    const invalidList = getInvalidFieldsFromFormData(fields, REQUIRED_ADDRESS_FORM_FIELDS);

    setInvalidFields([...invalidList]);

    return !invalidList.length;
  };

  const handleOnSubmit = async () => {
    const valid = validate();

    setIsInvalid(!valid);

    if (!valid) return Promise.reject();

    return onSubmit(getAddressInput()).catch(handleOnError);
  };

  const handleGetCities = async (event) => {
    stateRef.current = event.target;
    getCities({ variables: { country, state: event.target.value } });
  };

  const handleGetDistricts = async (event) => {
    const variables = { country };

    if (!stateRef.current) {
      variables.state = event.target.value;
    } else {
      variables.state = stateRef.current.value;
      variables.city = event.target.value;
    }

    getDistricts({ variables });
  };

  const ONCHANGE_CALLBACKS = {
    province: handleGetCities,
    zone: handleGetDistricts,
    city: handleGetDistricts,
  };

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { addressForm } = data.textFor;
  const { countryConfig } = data;

  // Renderers
  const renderCountrySelector = () => (
    <FormStructure.FormGroup key="address-form-country-selector">
      <CountrySelector
        flagBlock={
          <FlagBlock countryCode={countryConfig.id} countryName={countryConfig.label.value} />
        }
        link={(
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link onClick={handleOnCountryChangeClick}>
              <TmntText tmnt={textFor?.buttonChange} />
            </Link>
          </>
        )}
      />
    </FormStructure.FormGroup>
  );

  const renderButtonState = (initial, asyncOnClickHandler, variant, type) => (
    <ButtonState
      asyncOnClick={asyncOnClickHandler}
      onSuccess={onClose}
      initial={(
        <Button variant={variant} isFullWidth type={type}>
          <TmntText tmnt={initial} />
        </Button>
      )}
      processing={(
        <Button
          variant={variant}
          isProcessing
          isDisabled
          isFullWidth
        >
          <TmntText tmnt={textFor?.buttonProcessing} />
        </Button>
      )}
      error={(
        <Button variant={variant} isDisabled isFullWidth>
          <TmntText tmnt={textFor?.buttonPleaseTryAgain} />
        </Button>
      )}
      success={(
        <Button variant={variant} isDisabled isFullWidth>
          <Icon icon="check" />
          <TmntText tmnt={textFor?.buttonSuccess} />
        </Button>
      )}
    />
  );

  const processFieldAttributes = (field) => {
    const locationList = locations[field.name];

    const newField = {
      ...field,
      ...(locationList && { optionList: formatOptionList(locationList) }),
    };

    return {
      ...newField,
      isDisabled: isDisabledField(newField),
      isInvalid: isInvalidField(newField, invalidFields),
      isRequired: isRequiredField(newField, REQUIRED_ADDRESS_FORM_FIELDS),
      maxLength: getFieldMaxLengthFromMap(newField, ADDRESS_FORM_MAX_LENGTHS),
      onChange: getOnChange(newField, ONCHANGE_CALLBACKS),
      value: getValueFromData(newField, address),
    };
  };

  const renderField = (field) => (
    <AddressField
      {...field} // eslint-disable-line react/jsx-props-no-spreading
      key={`address-form-field-${field.name}`}
      type={field.__typename.toLowerCase()} // eslint-disable-line no-underscore-dangle
    />
  );

  const renderGroups = () => addressForm.formGroupList.map((group, index) => (
    <FormGroup
      key={`address-form-group-${index.toString()}`}
      label={(
        <span className="screen-reader-text">
          <TmntText tmnt={group.label} />
        </span>
      )}
      fields={[
        <FieldGroup legend="Address Form Fields">
          { group.fieldList.map((field) => {
            const fieldAttributes = processFieldAttributes(field);
            return renderField(fieldAttributes);
          }) }
        </FieldGroup>,
      ]}
    />
  ));

  const renderError = () => (
    <ErrorMessage id={`${addressForm.id}-error-message`}>
      <TmntText tmnt={addressForm.error} />
    </ErrorMessage>
  );

  return (
    <Form
      id={addressForm.id}
      label={(
        <span className="screen-reader-text">
          <TmntText tmnt={addressForm.label} />
        </span>
      )}
      error={renderError()}
      formRef={formRef}
      groups={[renderCountrySelector(), ...renderGroups()]}
      isInvalid={isInvalid}
      isValidated={false}
      onSubmit={(e) => { e.preventDefault(); }}
    >
      <FormStructure.FormGroup>
        <FormStructure.FormCell>
          <div data-testid="legal-text">
            <LegalButton
              text={textFor?.addressFormLegal}
              ref={legalLinkRef}
            />

            {legalTmntKey && (
              <ModalContextProvider>
                <LegalModal
                  legalText={legalTmntKey}
                  onClose={() => setLegalTmntKey(null)}
                />
              </ModalContextProvider>
            )}
          </div>
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
      {
        !isEdit
          ? (
            <FormStructure.FormGroup>
              <FormStructure.FormCell>
                {renderButtonState(textFor?.buttonAddAddress, handleOnSubmit, 'primary', 'submit')}
              </FormStructure.FormCell>
            </FormStructure.FormGroup>
          )
          : (
            <FormStructure.FormGroup>
              <FormStructure.FormCell>
                {renderButtonState(textFor?.buttonUpdate, handleOnSubmit, 'primary', 'submit')}
              </FormStructure.FormCell>
              <FormStructure.FormCell>
                {renderButtonState(textFor?.buttonRemove, onRemove, 'tertiary-dark')}
              </FormStructure.FormCell>
            </FormStructure.FormGroup>
          )
      }
    </Form>
  );
}

AddressForm.propTypes = propTypes;
AddressForm.defaultProps = defaultProps;
