import React from 'react';
import { LoadingButton } from '../Messages/Messages';

export const renderDesktopSignInContainer = (children) => (
  <div className="signin-block" data-testid="signin-block">
    { children }
  </div>
);

export const renderDesktopSignInLoading = () => renderDesktopSignInContainer(<LoadingButton />);
