import React from 'react';
import hydrate from '../lib/hydrate';
import { AuthenticationModalContext, ModalContextProvider } from '../lib/context/ModalContext';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';
import UpperFunnelLoginClient from '../lib/components/UpperFunnelLogin/UpperFunnelLoginClient';

function UpperFunnelLoginFrontend() {
  return (
    <ModalContextProvider context={AuthenticationModalContext}>
      <AuthenticationFormContextProvider>
        <UpperFunnelLoginClient />
      </AuthenticationFormContextProvider>
    </ModalContextProvider>
  );
}

hydrate({
  frontend: 'UpperFunnelLoginFrontend',
  component: UpperFunnelLoginFrontend,
  persist: true,
});
