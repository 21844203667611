import React, { useEffect, useRef, useState } from 'react';
import { RE2JS } from 're2js';
import { useQuery, useMutation } from '@apollo/client';
import {
  ErrorMessage,
  Icon,
} from 'anf-core-react';
import { useForm, useController } from 'react-hook-form';
import SectionHeading from './SectionHeading';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import useButtonState from '../Common/ButtonState/useButtonState';
import ReCaptcha from '../Common/ReCaptcha/ReCaptcha';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { giftCardBalanceFormQuery, giftCardBalanceRequest } from './operations';
import GiftCardBalanceFormFields from './GiftCardBalanceFormFields';
import GiftCardBalanceSuccess from './GiftCardBalanceSuccess';

export default function GiftCardBalanceForm() {
  const [giftCard, setGiftCard] = useState(null);
  const [renderButton, registerPromise] = useButtonState();
  const reCaptchaRef = useRef(null);
  const { loading, error: queryError, data: queryData } = useQuery(giftCardBalanceFormQuery);

  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    formState: { isDirty },
    reset,
    setError,
  } = useForm();

  const { field } = useController({
    name: 'reCaptcha',
    control,
    rules: { required: true, validate: (value) => value !== null },
  });

  const [giftCardBalanceMutation] = useMutation(giftCardBalanceRequest, {
    onError: (error) => {
      const { graphQLErrors } = error;
      const { extensions } = graphQLErrors && graphQLErrors.length && graphQLErrors[0];
      const { body } = extensions.response;
      setError('form', { message: body?.error[0]?.errorMessage });
    },
  });

  useEffect(() => {
    if (!isDirty) return;
    reCaptchaRef.current.reset();
  }, [isDirty]);

  const validateSubmission = (cardNumber, pinNumber = '') => {
    let isCardValid = true;
    let isPinValid = true;
    const eCardRegEx = RE2JS.compile('^[0-9]{16}$');
    const cardNumRegEx = RE2JS.compile('^[0-9]{12}$');
    const pinNumRegEx = RE2JS.compile('^[0-9]{4}$');
    const eCardValid = eCardRegEx.matches(cardNumber) && pinNumber.length === 0;

    if (!(cardNumRegEx.matches(cardNumber) || eCardValid)) {
      // Error for Card Number
      setError('gift-card-number-input', { type: 'invalidCardNumber' });
      isCardValid = false;
    }

    // Test for eGiftCard and Associated PIN
    if (!eCardValid && !(cardNumRegEx.matches(cardNumber) && pinNumRegEx.matches(pinNumber))) {
      // Error for PIN
      setError('gift-card-pin-input', { type: 'invalidPinNumber' });
      isPinValid = false;
    }

    return isCardValid && isPinValid;
  };

  const handleOnSubmit = (fieldValues) => {
    clearErrors();

    const {
      'gift-card-country-dropdown': country,
      'gift-card-number-input': giftCardAccountNumber,
      'gift-card-pin-input': giftCardPin,
      reCaptcha,
    } = fieldValues;

    const isSubmissionValid = validateSubmission(giftCardAccountNumber, giftCardPin);

    try {
      const call = new Promise((resolve, reject) => {
        if (!isSubmissionValid) {
          setError('form', { message: queryData.textFor.giftCardBalanceFormInvalidText?.value });
          reject();
        } else {
          // Update card number with PIN only when PIN is provided
          const cardNumber = giftCardPin ? `${giftCardAccountNumber}${giftCardPin}` : giftCardAccountNumber;

          giftCardBalanceMutation({
            variables: {
              country,
              cardNumber,
              recaptchaResponse: reCaptcha,
            },
          }).then(({ data = {} }) => {
            const { checkGiftCardBalance } = data;

            reCaptchaRef.current.reset(); // Reset the reCaptcha after submission

            if (!checkGiftCardBalance?.success) {
              const { errors } = checkGiftCardBalance;
              if (errors && errors.length) setError('form', { message: errors[0].message?.value });
              reject();
            } else {
              const { currency, balance } = checkGiftCardBalance;
              if (currency && balance) setGiftCard({ currency, balance });
              resolve();
            }
          })
            .catch(() => { reject(); });
        }
      });

      registerPromise(call);
    } catch { /* do nothing */ }
  };

  const handleFailure = (fieldErrors) => {
    if (Object.keys(fieldErrors).length === 1 && fieldErrors.form) {
      handleOnSubmit(getValues());
    } else {
      setError('form', { message: queryData.textFor.giftCardBalanceFormInvalidText?.value });
    }
  };

  const handleSuccessModalClose = () => { setGiftCard(null); };

  const handleFormReset = () => {
    reset();
    reCaptchaRef.current.reset();
    handleSuccessModalClose();
  };

  const renderCaptchaError = (errors) => {
    if (!errors.reCaptcha) return null;
    return (
      <FormCell>
        <ErrorMessage id="gift-card-balance-recaptcha-error-message">
          { queryData.textFor.giftCardReCaptchaErrorText?.value }
        </ErrorMessage>
      </FormCell>
    );
  };

  const renderFormError = (errors) => {
    const formError = errors.form;
    if (Object.keys(formState.errors).length <= 0) return null;
    const message = formError?.message || queryData.textFor.giftCardBalanceFormInvalidText?.value;

    return (
      !errors.reCaptcha && (
      <FormCell>
        <ErrorMessage id="gift-card-balance-form-error-message">
          { message }
        </ErrorMessage>
      </FormCell>
      )
    );
  };

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = queryData;

  const {
    checkYourBalanceHeadingText,
    checkBalanceButtonText,
    processing,
    success,
    pleaseTryAgain,
  } = textFor;

  return (
    <>
      <form onSubmit={handleSubmit(handleOnSubmit, handleFailure)} name="gift-card-balance-form" noValidate>
        <FormWrapper>
          <FormGroup>
            <FormCell>
              <SectionHeading SectionHeadingText={checkYourBalanceHeadingText} />
            </FormCell>
            <FormCell>
              <GiftCardBalanceFormFields control={control} queryData={queryData} />
            </FormCell>
            <FormCell>
              <ReCaptcha
                onChange={field.onChange}
                ref={reCaptchaRef}
              />
            </FormCell>
            { renderCaptchaError(formState.errors)}
            { renderFormError(formState.errors) }
            <FormCell>
              {
              renderButton({
                initial: {
                  children: (<TmntText tmnt={checkBalanceButtonText} />),
                  isFullWidth: true,
                  type: 'submit',
                  variant: 'secondary',
                },
                processing: {
                  children: (<TmntText tmnt={processing} />), isFullWidth: true, variant: 'secondary',
                },
                error: {
                  children: (<TmntText tmnt={pleaseTryAgain} />), isFullWidth: true, variant: 'secondary',
                },
                success: {
                  children: (
                    <>
                      <Icon icon="check" />
                      <TmntText tmnt={success} />
                    </>
                  ),
                  isFullWidth: true,
                  variant: 'secondary',
                },
              })
            }
            </FormCell>
          </FormGroup>
        </FormWrapper>
      </form>
      <GiftCardBalanceSuccess
        giftCardBalance={giftCard?.balance}
        isOpen={!!giftCard}
        onCheckAnotherCardBalance={handleFormReset}
        onClose={handleSuccessModalClose}
      />
    </>
  );
}
