import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

const defaultProps = { id: undefined };

export default function FormWrapper({ children, id }) {
  return (
    <div
      className="account-form"
      id={id}
      data-testid={id}
    >
      <div className="account-form-inner-wrapper">
        { children }
      </div>
    </div>
  );
}

FormWrapper.propTypes = propTypes;
FormWrapper.defaultProps = defaultProps;
