import PropTypes from 'prop-types';
import { decodeHtmlEntities } from '../../../tools/html/decode';

const propTypes = { value: PropTypes.string.isRequired };

/**
 * EncodedText component that decodes HTML entities in a string.
 *
 * @component
 * @param {string} props.value - The HTML-encoded string to be decoded
 * @returns {string} Decoded string with HTML entities converted to their corresponding characters
 * @example
 * <EncodedText value="&amp;quot;Hello&amp;quot;" />
 * // Returns: "Hello"
 */
export default function EncodedText({ value }) {
  return decodeHtmlEntities(value);
}

EncodedText.propTypes = propTypes;
