import React from 'react';
import hydrate from '../lib/hydrate';
import LoggedOutLeftNav from '../lib/components/Common/LeftNav/LoggedOutLeftNav';
import AuthenticationModal from '../lib/components/SignIn/AuthenticationModal';
import { AuthenticationModalContext, ModalContextProvider } from '../lib/context/ModalContext';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';

function LoggedOutLeftNavFrontend() {
  return (
    <ModalContextProvider context={AuthenticationModalContext}>
      <AuthenticationFormContextProvider>
        <AuthenticationModal />
        <LoggedOutLeftNav />
      </AuthenticationFormContextProvider>
    </ModalContextProvider>
  );
}

hydrate({
  frontend: 'LoggedOutLeftNavFrontend',
  component: LoggedOutLeftNavFrontend,
});
