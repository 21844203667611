import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import {
  ApolloClient, ApolloProvider, HttpLink, from,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { sha256 } from 'crypto-hash';
import jsCookie from 'js-cookie';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { persistCache, SessionStorageWrapper } from 'apollo3-cache-persist';
import { cookies as cookieUtilities, signifyd as signifydUtilities } from '@xp-utilities/utilities';
import App from './App';
import {
  applicationContextVar,
  xSegVar,
  userTypeVar,
  countryVar,
  signifydIdVar,
  updatePlacementsVar,
} from './context/ApplicationContext';
import { buildBFFUri } from '../tools/uri';
import { getCacheId, getInMemoryCacheSettings } from '../tools/cache';
import persistenceMapper from '../tools/persist/persistenceMapper';
import persistenceRules from '../tools/persist/rules';
import { handleGraphQLError } from './tools/errorHandling';
import getFrontendId from '../tools/getFrontendId';

const updateXSegVar = (ctx) => {
  const { cookies = {} } = ctx;
  xSegVar(cookies.xSeg || null);
};

const updateUserTypeVar = (ctx) => {
  userTypeVar(cookieUtilities.getUserType(ctx));
};

const updateCountryVar = (ctx) => {
  const { query = {} } = ctx;
  countryVar(query.country);
};

const updateSignifydIdVar = () => {
  if (!document) return;

  const signifydSessionID = signifydUtilities.getSignifydSessionID(document);
  signifydIdVar(signifydSessionID);
};

const updateApplicationContextVar = (ctx) => {
  const { query } = ctx;

  if (!query) {
    applicationContextVar(null);
    return;
  }

  const includeList = ['brand', 'catalogId', 'country', 'langId', 'store', 'storeId'];

  const filteredQueryObj = Object.keys(query)
    .filter((key) => includeList.includes(key))
    .reduce((obj, key) => {
      const object = obj;
      object[key] = query[key];
      return object;
    }, {});

  applicationContextVar(JSON.stringify(filteredQueryObj));
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      const { message, locations, path } = err;

      handleGraphQLError(err);

      // eslint-disable-next-line no-console
      console.log(
        '[GraphQL error]: Message: ',
        message,
        'Location: ',
        locations,
        'Path',
        path,
      );
    });
  }

  // eslint-disable-next-line no-console
  if (networkError) console.log('[Network error]: ', networkError);
});

/**
 * Hydrates a React component into the DOM with Apollo Client setup.
 *
 * @async
 * @function hydrate
 * @param {object} options - The configuration options for hydration.
 * @param {string} options.frontend - The frontend identifier.
 * @param {React.ComponentType} options.component - The React component to hydrate.
 * @param {string} options.clientId - Unique client identifier.
 * @param {boolean} [options.persist=false] - Whether to persist the Apollo cache in
 * session storage.
 * @param {boolean} [options.useGlobalPersistence=true] - Whether to use a global persistence
 * key for the cache. Defaults to ON (true)
 *
 * @returns {Promise<null|React.ReactElement>} Resolves to the hydrated React component or `null`
 * if the DOM element is not found.
 */
export default async function hydrate({
  frontend,
  component,
  clientId,
  persist = false,
  useGlobalPersistence = true,
}) {
  const Component = component;
  const cacheId = getCacheId(frontend, clientId);
  const frontendId = getFrontendId(frontend, clientId);
  const el = document.getElementById(frontendId);

  // To support a single file js type delivery, need to handle component endpoints
  // existing in js, but not in dom
  if (!el) return null;

  const config = window[`APOLLO_STATE__${cacheId}`] || {};
  const { query } = config;

  config.cookies = jsCookie.get();

  // Update the applicationContextVar and setup the cache
  updateApplicationContextVar(config);

  // Update the xSegVar
  updateXSegVar(config);

  updateUserTypeVar(config);
  updateCountryVar(config);
  updateSignifydIdVar();

  updatePlacementsVar(config, clientId);

  const inMemoryCacheSettings = getInMemoryCacheSettings();
  const cache = new InMemoryCache(inMemoryCacheSettings).restore(config.CACHE);

  if (persist) {
    const PERSIST_SUFFIX = 'persist-cache';

    const persistKey = !useGlobalPersistence
      ? `${frontendId}-${PERSIST_SUFFIX}`
      : `${PACKAGE.name}-${PERSIST_SUFFIX}`;

    try {
      await persistCache({
        cache,
        storage: new SessionStorageWrapper(window.sessionStorage),
        key: persistKey,
        persistenceMapper: (data) => persistenceMapper(data, persistenceRules),
      });
    // eslint-disable-next-line no-console
    } catch (err) { console.log('[Persist Cache Error]: ', err); }
  }

  // Goal uri example: 'http://localhost:51902?storeId=10051&catalogId=10901&langId=-1&brand=anf&store=a-us',
  const uri = buildBFFUri(query);
  const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

  const batchHttpLink = new BatchHttpLink({
    uri,
    credentials: 'same-origin',
    batchMax: 50,
    batchInterval: 10,
  });

  const httpLink = new HttpLink({ uri, credentials: 'same-origin' });

  const splitLink = (operation) => {
    const operationType = operation?.query?.definitions?.[0]?.operation;

    if (operationType === 'mutation') return true;

    const { batch = false } = operation.getContext();
    return batch;
  };

  // Custom link to add operationName to headers, this is leveraged by our CDN
  const addOperationNameLink = setContext((operation, previousContext) => {
    const { headers = {}, operationName } = previousContext;
    const monitoringOperationName = operationName || operation?.operationName || 'unknown-operation';

    return {
      headers: { ...headers, 'x-operation-name': monitoringOperationName },
    };
  });

  /*
    Batched queries and mutations are made over POST calls and shouldn't be cached.
    persistExtractionLink should be called after the persistedQueriesLink
  */
  const client = new ApolloClient({
    cache,
    link: from([addOperationNameLink, errorLink])
      .split(
        splitLink,
        batchHttpLink,
        persistedQueriesLink.concat(httpLink),
      ),
  });

  return hydrateRoot(
    el,
    <ApolloProvider client={client}>
      <App
        clientId={clientId}
        frontend={frontend}
        persist={persist}
        useGlobalContextKey={useGlobalPersistence}
      >
        <Component clientId={clientId} />
      </App>
    </ApolloProvider>,
  );
}

/**
 * Hydrates the Order Details MFE component when the script is loaded.
 * This function is specific to the Order Details MFE and checks the Launch Darkly feature flag
 * to determine if it should run.
 *
 * @async
 * @function hydrateOrderDetails
 * @param {object} options - The configuration options for hydration.
 * @param {string} options.frontend - The frontend identifier.
 * @param {React.ComponentType} options.component - The React component to hydrate.
 * @param {string} options.clientId - Unique client identifier.
 * @param {boolean} [options.persist=false] - Whether to persist the Apollo cache.
 * @param {boolean} [options.useGlobalPersistence=true] - Use global persistence key for cache.
 * @param {boolean} [options.checkLaunchDarkly=true] - Whether to check Launch Darkly feature flag.
 *
 * @returns {Promise<null|React.ReactElement>} Resolves to the hydrated React component or `null`
 * if the DOM element is not found or the feature flag is not enabled.
 */
export async function hydrateOrderDetails({
  frontend,
  component,
  clientId,
  persist = false,
  useGlobalPersistence = true,
  checkLaunchDarkly = true,
}) {
  if (checkLaunchDarkly) {
    const isOrderDetailsFlagEnabled = window.digitalData?.export()?.flag?.['has-new-order-details-api'] || false;

    if (!isOrderDetailsFlagEnabled) {
      // eslint-disable-next-line no-console
      console.log('[Order Details MFE]: Feature flag is not enabled');
      return null;
    }
  }

  const Component = component;
  const cacheId = getCacheId(frontend, clientId);
  const frontendId = getFrontendId(frontend, clientId);
  const el = document.getElementById(frontendId);

  // To support a single file js type delivery, need to handle component endpoints
  // existing in js, but not in dom
  if (!el) return null;

  const config = window[`APOLLO_STATE__${cacheId}`] || {};
  const { query } = config;

  config.cookies = jsCookie.get();

  // Update the applicationContextVar and setup the cache
  updateApplicationContextVar(config);

  // Update the xSegVar
  updateXSegVar(config);

  updateUserTypeVar(config);
  updateCountryVar(config);
  updateSignifydIdVar();

  updatePlacementsVar(config, clientId);

  const inMemoryCacheSettings = getInMemoryCacheSettings();
  const cache = new InMemoryCache(inMemoryCacheSettings).restore(config.CACHE);

  if (persist) {
    const PERSIST_SUFFIX = 'persist-cache';

    const persistKey = !useGlobalPersistence
      ? `${frontendId}-${PERSIST_SUFFIX}`
      : `${PACKAGE.name}-${PERSIST_SUFFIX}`;

    try {
      await persistCache({
        cache,
        storage: new SessionStorageWrapper(window.sessionStorage),
        key: persistKey,
        persistenceMapper: (data) => persistenceMapper(data, persistenceRules),
      });
    // eslint-disable-next-line no-console
    } catch (err) { console.log('[Persist Cache Error]: ', err); }
  }

  // Build the URI for Order Details BFF endpoint
  const uri = buildBFFUri(query);
  const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

  const batchHttpLink = new BatchHttpLink({
    uri,
    credentials: 'same-origin',
    batchMax: 50,
    batchInterval: 10,
  });

  const httpLink = new HttpLink({ uri, credentials: 'same-origin' });

  const splitLink = (operation) => {
    const operationType = operation?.query?.definitions?.[0]?.operation;

    if (operationType === 'mutation') return true;

    const { batch = false } = operation.getContext();
    return batch;
  };

  // Custom link to add operationName to headers, this is leveraged by our CDN
  const addOperationNameLink = setContext((operation, previousContext) => {
    const { headers = {}, operationName } = previousContext;
    const monitoringOperationName = operationName || operation?.operationName || 'unknown-operation';

    return {
      headers: {
        ...headers,
        'x-operation-name': monitoringOperationName,
        'x-order-details-mfe': 'true', // Header to identify Order Details MFE requests
      },
    };
  });

  /*
    Batched queries and mutations are made over POST calls and shouldn't be cached.
    persistExtractionLink should be called after the persistedQueriesLink
  */
  const client = new ApolloClient({
    cache,
    link: from([addOperationNameLink, errorLink])
      .split(
        splitLink,
        batchHttpLink,
        persistedQueriesLink.concat(httpLink),
      ),
  });

  // Log that Order Details MFE is being hydrated
  // eslint-disable-next-line no-console
  console.log(`[Order Details MFE]: Hydrating ${frontendId}`);

  return hydrateRoot(
    el,
    <ApolloProvider client={client}>
      <App
        clientId={clientId}
        frontend={frontend}
        persist={persist}
        useGlobalContextKey={useGlobalPersistence}
      >
        <Component clientId={clientId} />
      </App>
    </ApolloProvider>,
  );
}
