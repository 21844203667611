import React from 'react';
import hydrate from '../lib/hydrate';
import { AddressPageClient } from '../lib/components/Address/AddressPage';

function AddressPageFrontend() {
  return (
    <AddressPageClient />
  );
}

hydrate({
  frontend: 'AddressPageFrontend',
  component: AddressPageFrontend,
});
