import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import AuthenticationModal from './AuthenticationModal';
import MobileSignInButton from './MobileSignInButton';
import SignInMenu from './SignInMenu';
import SignInToaster from './SignInToaster';
import { SignInToasterContext } from '../../context/ModalContext';
import { LoadingButton } from '../Messages/Messages';
import { MOBILE_SIGN_IN_QUERY } from './operations';

const MOBILE_SIGN_IN = 'mobile-sign-in';

const renderContainer = (children) => (
  <div className={MOBILE_SIGN_IN} data-testid={MOBILE_SIGN_IN}>
    { children }
  </div>
);

export default function MobileSignIn() {
  const { openModal: openToaster } = useContext(SignInToasterContext);

  const { loading, error, data = {} } = useQuery(
    MOBILE_SIGN_IN_QUERY,
    {
      ssr: false,
      context: { batch: true },
      fetchPolicy: 'cache-and-network',
    },
  );

  if (loading) return <LoadingButton showText={false} />;
  if (error) return null;

  const { userState = {}, textFor = {} } = data;
  const { isLoggedIn = false } = userState;
  const { signInOrJoinText = {}, viewAccountText = {} } = textFor;
  const label = !isLoggedIn ? signInOrJoinText : viewAccountText;

  return renderContainer((
    <>
      <MobileSignInButton
        onClick={openToaster}
        label={label}
      />
      {/* Auth Modal */}
      <AuthenticationModal />

      {/* Sign In Toaster */}
      <SignInToaster>
        <SignInMenu
          data-testId="mobile-account-menu"
          isLoggedIn={isLoggedIn}
        />
      </SignInToaster>

      { /* OFF - Sign In Rewards Communication Modal */ }
      { /* { isLoggedIn && <SignInRewardsCommunicationModal /> } */ }
    </>
  ));
}
