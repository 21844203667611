import PropTypes from 'prop-types';

export const sessionContextProps = {
  children: PropTypes.node.isRequired,
  frontend: PropTypes.string.isRequired,
  clientId: PropTypes.string,
  useGlobalContextKey: PropTypes.bool,
};

export const sessionContextDefaultProps = {
  clientId: undefined,
  useGlobalContextKey: true,
};
