import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { textPairFragment } from '../../query/fragments';
import FooterEmailSubscribeModal from './FooterEmailSubscribeModal';

const FOOTER_EMAIL_SUBSCRIBE_BUTTON_QUERY = gql`
  query FOOTER_EMAIL_SUBSCRIBE_BUTTON_QUERY {
    config {
      brand: string(name: "brand")
    }
    textFor {
      footerSubscribeSignUpForEmailsButtonText: pair(pairKey: "footerSubscribeSignUpForEmailsButtonText") {...textPair}
    }
  }

  ${textPairFragment}
`;

export default function FooterEmailSubscribeButton() {
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    loading, error: queryError, data: queryData,
  } = useQuery(FOOTER_EMAIL_SUBSCRIBE_BUTTON_QUERY);

  if (loading) return null;
  if (queryError) return ERROR_MESSAGE;

  const { brand } = queryData.config;
  const { footerSubscribeSignUpForEmailsButtonText } = queryData.textFor;

  return (
    <>
      <Button
        isFullWidth
        onClick={() => setModalOpen(true)}
        theme={brand !== 'kids' ? null : 'inverse'}
        variant="tertiary-light"
      >
        <TmntText tmnt={footerSubscribeSignUpForEmailsButtonText} />
      </Button>
      <FooterEmailSubscribeModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
}
