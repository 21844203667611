import React from 'react';
import {
  Badge,
  ErrorMessage,
  FieldGroup,
} from 'anf-core-react';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import { TmntText } from '../Common/Text';
import * as FormStructure from '../FormStructure';
import { GENDER_OPTION_MAP } from './constants';

export const processGenderList = ({ optionValue, text }) => {
  const value = GENDER_OPTION_MAP[optionValue] || optionValue;
  return ({ label: text?.value, value });
};

export const renderFormGroup = (formGroup, index, renderFieldFn) => {
  if (!formGroup) return null;

  return (
    <FieldGroup
      key={`form-group-${index}`}
      legend={formGroup.label?.value}
    >
      { formGroup.fieldList?.map((field) => renderFieldFn(field)) }
    </FieldGroup>
  );
};

export const renderBadge = (label) => (
  <div className="info-banner-success-banner">
    <Badge>
      <TmntText tmnt={label} />
    </Badge>
  </div>
);

export const renderSectionHeading = (header) => (
  <SectionHeading>
    <h2 className="h3">
      <TmntText tmnt={header} />
    </h2>
  </SectionHeading>
);

export const renderErrorMessage = (condition, errorId, text) => (
  condition && (
    <FormStructure.FormCell>
      <ErrorMessage id={errorId}><TmntText tmnt={text} /></ErrorMessage>
    </FormStructure.FormCell>
  )
);
