import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useApolloClient, useLazyQuery, useReactiveVar } from '@apollo/client';
import { applicationContextVar } from '../ApplicationContext';
import getFrontendId from '../../../tools/getFrontendId';
import { SESSION_CONTEXT_QUERY } from './operations';
import { sessionContextProps, sessionContextDefaultProps } from './props';

const SessionContext = createContext(null);
export default SessionContext;

/**
 * Builds a session context key for session storage.
 *
 * @param {string} [frontend] - The frontend identifier.
 * @param {string} [clientId] - The client identifier.
 * @param {boolean} [isGlobal] - Determines whether to use global or frontend
 * specific key.
 * @returns {string} The generated session context key.
 */
const buildSessionContextKey = (frontend, clientId, isGlobal = false) => {
  const sessionContextSuffix = 'session-context';
  if (isGlobal) return `${PACKAGE.name}-${sessionContextSuffix}`;
  return `${getFrontendId(frontend, clientId)}-${sessionContextSuffix}`;
};

/**
 * Constructs a string representation of the session context.
 *
 * @param {string} sessionId - The session identifier.
 * @param {string} ctx - The application context.
 * @returns {string} The session context string.
 */
const buildSessionContextString = (sessionId, ctx) => `${sessionId}:${ctx}`;

let promise = null;

/**
 * React Provider component for the SessionContext.
 *
 * This component provides a session context to its children and manages
 * session-related logic such as context keys, stale checks, and cache resets.
 *
 * When `frontend` isn't passed a global cache instance is used
 *
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The children components to wrap within the provider.
 * @param {string} [props.clientId] - The client identifier for building session keys.
 * @param {string} [props.frontend] - The frontend identifier for building session keys.
 * @param {Boolean} [props.useGlobalContextKey] - Flag used to identify whether to use frontend
 * specific or global session storage key.
 * @returns {React.ReactElement} The rendered provider component.
 */
export function SessionContextProvider({
  children,
  clientId,
  frontend,
  useGlobalContextKey,
}) {
  const appContext = useReactiveVar(applicationContextVar);
  const client = useApolloClient();

  const sessionContextKey = useMemo(
    () => buildSessionContextKey(frontend, clientId, useGlobalContextKey),
    [frontend, clientId, useGlobalContextKey],
  );

  /**
   * Checks if the current session context in storage is stale.
   *
   * @param {string} id - The session identifier to compare.
   * @returns {boolean} True if the session context is stale, false otherwise.
   */
  const isStale = useCallback((id) => {
    const current = window.sessionStorage.getItem(sessionContextKey);
    if (!current) return false; // Note: no entry, so can't be stale
    const expected = buildSessionContextString(id, appContext);
    return current !== expected;
  }, [appContext, sessionContextKey]);

  /**
   * Resets the session context by removing the current session context key
   * and resetting the Apollo client store.
   */
  const resetSessionContext = () => {
    window.sessionStorage.removeItem(sessionContextKey);
    client.resetStore();
  };

  /**
   * Callback for when the session query completes successfully.
   *
   * @param {object} data - The data returned from the query.
   * @param {object} data.userState - The user's state data.
   * @param {string} data.userState.sessionId - The current session identifier.
   */
  const onSessionQueryCompleted = ({ userState }) => {
    const { sessionId } = userState;

    // Reset the session storage when session id or appContext changes
    if (isStale(sessionId)) resetSessionContext();

    const contextString = buildSessionContextString(sessionId, appContext);

    // Set the session context string
    window.sessionStorage.setItem(sessionContextKey, contextString);
  };

  const [fetch] = useLazyQuery(SESSION_CONTEXT_QUERY, {
    ssr: false,
    context: { batch: true },
    fetchPolicy: 'cache-and-network',
    onCompleted: onSessionQueryCompleted,
  });

  /**
   * Garbage collects unused cache entries on every render.
   *
   * This helps ensure that the Apollo Client cache remains optimized
   * and doesn't accumulate unnecessary data.
   */
  useEffect(() => { client.cache.gc(); }, [client]);

  /**
   * Keep track of promise to ensure duplicate calls are minimized
   */
  useEffect(() => {
    if (!promise) promise = fetch().finally(() => { promise = null; });
  }, [fetch]);

  return (
    <SessionContext.Provider value={null}>
      { children }
    </SessionContext.Provider>
  );
}

SessionContextProvider.propTypes = sessionContextProps;
SessionContextProvider.defaultProps = sessionContextDefaultProps;
