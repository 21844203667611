import React from 'react';
import hydrate from '../lib/hydrate';
import SignInBlock from '../lib/components/SignIn/SignInBlock';
import {
  AuthenticationModalContext,
  ModalContextProvider,
  SignInToasterContext,
} from '../lib/context/ModalContext';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';

function SignInBlockFrontend() {
  return (
    <ModalContextProvider context={AuthenticationModalContext}>
      <ModalContextProvider context={SignInToasterContext}>
        <AuthenticationFormContextProvider>
          <SignInBlock />
        </AuthenticationFormContextProvider>
      </ModalContextProvider>
    </ModalContextProvider>
  );
}

hydrate({
  frontend: 'SignInBlockFrontend',
  component: SignInBlockFrontend,
  persist: true,
});
