import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'anf-core-react';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import { TmntText } from '../Common/Text';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import { textProp } from '../../tools/customProps';

const propTypes = {
  welcomeMessageText: textProp.isRequired,
  loyaltyTierImageSrc: PropTypes.string.isRequired,
  memberSince: textProp.isRequired,
  loyaltyTierImageAltText: PropTypes.string.isRequired,
  isVip: PropTypes.bool,
};
const defaultProps = {
  isVip: false,
};

export default function LoyaltyWelcomeMessage({
  welcomeMessageText,
  loyaltyTierImageSrc,
  loyaltyTierImageAltText,
  memberSince,
  isVip,
}) {
  return (
    <AccountBlock
      classList="loyalty-welcome-message"
      heading={(
        <SectionHeading>
          <div>
            <h1 className="h2" data-testid="welcome-message-heading">
              <TmntText tmnt={welcomeMessageText} />
            </h1>
            <span>
              <TmntText tmnt={memberSince} />
            </span>
          </div>
        </SectionHeading>
      )}
      rightHeading={(
        <SectionHeading alignedRight>
          <Icon
            icon={loyaltyTierImageSrc}
            size={isVip ? 'xl' : null}
            isImage
            labelText={loyaltyTierImageAltText}
          />
        </SectionHeading>
      )}
    />
  );
}

LoyaltyWelcomeMessage.propTypes = propTypes;
LoyaltyWelcomeMessage.defaultProps = defaultProps;
